import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import ColorChip from "../ColorChip";

class ColorsBlock extends Component {
  constructor() {
    super();
    this.state = {
      colorName: "",
    };
    this.defaultColorName = "";
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.createColorChip = this.createColorChip.bind(this);
  }
  setStyle(e, styleId, mainImage, color) {
    const { primaryColor, secondaryColor } = color;
    const colorName = secondaryColor || primaryColor;
    if (this.state.colorName !== colorName) this.setState({ colorName });
    this.props.setStyle(e, styleId, mainImage);
  }
  createColorChip(color) {
    if (!color) {
      return null;
    }
    let selected = false;
    const { primaryColor, secondaryColor, styleId } = color;
    if (styleId === this.props.styleId) {
      const colorName = secondaryColor || primaryColor;
      this.defaultColorName = colorName;
      selected = true;
    }
    return (
      <ColorChip.component
        color={color}
        onHover={this.mouseEnter}
        setStyle={this.setStyle}
        isSelected={selected}
        key={styleId}
      />
    );
  }
  mouseEnter(color) {
    const { primaryColor, secondaryColor, mainImage } = color;
    const colorName = secondaryColor || primaryColor;
    this.props.setMainImage(mainImage);
    this.setState({ colorName });
  }
  mouseLeave() {
    const { defaultColorName: colorName } = this;
    this.props.setMainImage(null);
    this.setState({ colorName });
  }
  render() {
    const { ProductLoader, colors: colorsObj, title, className } = this.props;
    let colorsArray = [];
    let colors = null;
    if (ProductLoader) {
      colors = (
        <Fragment>
          <div className="nw-color-title nwc-contentloader" />
          <div className="nw-color-block">
            <div className="nwc-contentloader" />
          </div>
        </Fragment>
      );
    } else if (colorsObj) {
      colorsArray = Object.entries(colorsObj).map(([finish, colorArray]) => (
        <div
          className="nw-color-chips"
          key={finish}
          onMouseOut={this.mouseLeave}
          onBlur={this.mouseLeave}
        >
          {finish ? <div className="nw-color-finish">{finish}</div> : ""}
          {colorArray.map(color => this.createColorChip(color))}
        </div>
      ));
      colors = (
        <Fragment>
          <div className="nw-color-title">
            {title}&nbsp;
            <span className="nw-color-name">
              {this.state.colorName || this.defaultColorName}
            </span>
          </div>
          <div className={`nw-color-block ${className}`}>{colorsArray}</div>
        </Fragment>
      );
    }
    return colors;
  }
}
ColorsBlock.defaultProps = {
  styleId: "",
  title: "COLOR:",
};

ColorsBlock.propTypes = {
  setMainImage: PropTypes.func.isRequired,
  styleId: PropTypes.string,
  setStyle: PropTypes.func.isRequired,
  ProductLoader: PropTypes.bool.isRequired,
  colors: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ColorsBlock,
  component: ColorsBlock,
};

export default ProjectComponent;
