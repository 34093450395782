import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { LazyImg } from "arv-reactcomponents";
import LazyLoad from "react-lazyload";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  pageType,
  GETTYPE_PAGEWISE_VALUES,
  Endpoints,
  MASTERTEMPLATE
} from "npmlinks-constants";
import { imageSize, isApp } from "../../../utils";
import Analytics from "../../../analytics";
import pushToData from "../../../analytics/JetRay";
import Event from "../../../analytics/eventFactory";
import ProductBtns from "../ProductBtns";
import PriceBlock from "../PriceBlock";
import ProductPromotions from "../ProductPromotions";
import ColorsAvail from "../ColorsAvail";
import ProductTags from "../../ProductTags";
import Anchor from "../../Anchor";

import PdpFavoriteComponentV2 from "../../Favorite";
import { isPdpPage } from "../../../utils";
import GTM from "../../../analytics/GTM";

@inject("ProductStore", "BrandStore", "CommonStore")
@observer
class ProductView extends Component {
  static historyState(link) {
    return {
      pathname: link,
      state: {
        currentPage: pageType.pdp
      }
    };
  }
  static trackImageInViewPort(isDesktop, index, styleId, identity) {
    const page = Math.trunc((index || 1) / imageSize) + 1;
    const device = (isDesktop && "desktop") || "mobile";
    if (window.pageType === pageType.listing) {
      Analytics.trackEvent({
        action: Event.action.getProductImpression(
          page,
          imageSize,
          device,
          index
        ),
        label: Event.label.getMasterTemplateLabels([styleId, identity]),
        category: Event.category.LISTING_PAGE,
        interaction: true,
        toLogman: false,
        logmanEventName: Event.LogmanEvent.PRODUCT_IMPRESSION
      }, true);
    }
  }

  static trackImageError(imgSrc, identity) {
    Analytics.trackEvent({
      action: imgSrc,
      label: Event.label.getMasterTemplateLabels([
        Event.label.getSource(window.pageType),
        identity
      ]),
      category: Event.category.IMG_FAIL
    });
  }

  static linkTarget(isDesktop) {
    return (isDesktop && "_blank") || (isApp && "_self") || "";
  }

  constructor(props) {
    super(props);

    this.state = {
      defaultMainImage: null,
      hidePlaceHolder: global && global.isServer
    };

    this.onMainImgLoad = this.onMainImgLoad.bind(this);
    this.onMainImgError = this.onMainImgError.bind(this);
    this.onAltImgError = this.onAltImgError.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.returnProductTag = this.returnProductTag.bind(this);
    this.trackProductClick = this.trackProductClick.bind(this);
  }

  get imageAlt() {
    const { brandText, productTitle } = this.props;

    return `${brandText} ${productTitle}`;
  }

  trackProductClick(index, styleId, origin, title, link, identity) {
    const { isDesktop } = this.props.CommonStore;
    const linkArray = (link && link.split("-")) || [];
    const gaStyleId = styleId || linkArray[linkArray.length - 1];
    const page = Math.ceil((index || 1) / imageSize);
    const device = (isDesktop && "desktop") || "mobile";
    const props = this.props;
    const {productTitle, promotions, discountRange, brandText, mrpRange} = props;
     let gaProductInfo = {
        item_id: styleId,
        item_name: productTitle,
        coupon: promotions,
        currency: "INR",
        discount: discountRange.max || 0,
        item_brand: brandText,
        price: mrpRange.max || 0,
        quantity: 1
      };
    if (window.pageType === MASTERTEMPLATE.BT) {
      const { sectionIndex, componentIndex } = this.props;
      Analytics.trackEvent({
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.BT_PCAR_CLICK
        ]),
        label: Event.label.getMasterTemplateLabels([
          componentIndex,
          index,
          gaStyleId,
          identity
        ]),
        category: this.props.BrandStore.isBT
      });
    } else if (window.pageType === pageType.listing) {
      Analytics.trackEvent({
        action: Event.action.getProductClick(page, imageSize, device, index),
        label: Event.label.getMasterTemplateLabels([gaStyleId, identity]),
        category: Event.category.LISTING_PAGE
      });
      GTM.pushWithEvent(
        {"items": [gaProductInfo]},
        Event.GTMEvent.SELECT_ITEM,
      );
      pushToData(gaStyleId);
    } else if (
      GETTYPE_PAGEWISE_VALUES.MASTER_TEMPLATE.includes(window.pageType)
    ) {
      Analytics.trackEvent({
        category: window.pageType,
        action: Event.action.getMasterTemplateActions([
          origin,
          Event.action.PRODUCT_CAROUSEL
        ]),
        label: Event.label.getMasterTemplateLabels([
          title,
          index,
          gaStyleId,
          identity
        ])
      });
    } else {
      Analytics.trackEvent({
        action: Event.action.secondaryProductsClick(index, origin),
        label: Event.label.getMasterTemplateLabels([gaStyleId, identity]),
        category: Event.category.PRODUCT_DESCRIPTION_PAGE
      });
    }
  }
  onMainImgLoad() {
    const { index, styleId, identity } = this.props;
    const { isDesktop } = this.props.CommonStore;
    ProductView.trackImageInViewPort(isDesktop, index, styleId, identity);

    if (/\.png$/.test(this.productMainImage)) {
      this.setState({
        hidePlaceHolder: true
      });
    }
  }

  onProductClick() {
    const {
      index,
      altImage,
      link,
      brandText,
      productTitle,
      discountRange,
      mrpRange,
      isNew,
      sellingPriceRange,
      promotions,
      isAllPromotions,
      styleId,
      origin,
      moreColors,
      title,
      productTags,
      identity
    } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const obj = {
      mainStyle: {
        index,
        altImage,
        link,
        brandName: brandText,
        name: productTitle,
        discountRange,
        mrpRange,
        isNew,
        sellingPriceRange,
        promotions,
        isAllPromotions,
        styleId,
        moreColors,
        images: [{ medium: this.productMainImage }],
        productTags
      }
    };

    // const { index, styleId } = this.props;
    if (!isDesktop) {
      this.props.ProductStore.setPdpData(obj);
    }
    this.trackProductClick(index, styleId, origin, title, link, identity);
  }

  onMainImgError() {
    const { mainImage, identity } = this.props;
    this.setState({
      defaultMainImage: Endpoints.noImg
    });
    ProductView.trackImageError(mainImage, identity);
  }

  onAltImgError() {
    const { altImage, identity } = this.props;
    ProductView.trackImageError(altImage, identity);
  }

  returnProductTag() {
    const { productTags } = this.props;
    if (productTags && productTags.length) {
      return (
        <ProductTags.component
          tagData={productTags}
          className="nw-productview-producttags"
        />
      );
    }
    return (
      <div
        className={`
          nw-productview-isnewtxt
          ${this.isNewClassName}
        `}
      >
        NEW IN
      </div>
    );
  }

  get isNewClassName() {
    return (!this.props.isNew && "nwc-hide") || "";
  }

  get isSingleImageClassName() {
    return (!this.props.altImage && "is-singleimg") || "";
  }

  get imageStyleObj() {
    return {
      backgroundColor: this.props.imageColor
    };
  }

  get productMainImage() {
    return (
      this.state.defaultMainImage || this.props.mainImage || Endpoints.noImg
    );
  }

  get hiddenPlaceholderClassName() {
    return this.state.hidePlaceHolder ? "nwc-hide" : "";
  }

  render() {
    const {
      index,
      currentIndex,
      altImage,
      link,
      brandText,
      productTitle,
      discountRange,
      mrpRange,
      isNew,
      sellingPriceRange,
      promotions,
      isAllPromotions,
      styleId,
      moreColors,
      onWinLoad,
      productTags,
      inStock
    } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const { pathname: domain } = window.location;

    return (
      <Anchor.component
        href={link}
        to={ProductView.historyState(link)}
        className="nw-productview"
        target={ProductView.linkTarget(isDesktop)}
        onClick={this.onProductClick}
      >
        <div className="nwc-hide">{link}</div>
        <div className="nwc-hide">{this.productMainImage}</div>
        <div className="nw-productview-imgcontainer">
          {!inStock ? (
            <div className="nw-productview-outofstock">OUT OF STOCK</div>
          ) : (
            ""
          )}
          <div className="nw-productview-imgwrapper">
            <div
              className={`nw-productview-imgbgblk ${this.hiddenPlaceholderClassName}`}
              style={this.imageStyleObj}
            />
            {isDesktop ? (
              <LazyLoad>
                <LazyImg
                  index={currentIndex}
                  src={altImage}
                  className="nw-productview-img nw-productview-imgalt"
                  alt={this.imageAlt}
                  rootMargin={`1500px`}
                  onError={this.onAltImgError}
                  onWinLoad={onWinLoad}
                />
              </LazyLoad>
            ) : (
              ""
            )}
            <LazyLoad>
              <LazyImg
                index={currentIndex}
                src={this.productMainImage}
                className={`nw-productview-img nw-productview-imgmain ${this.isSingleImageClassName}`}
                rootMargin={`1500px`}
                onLoad={this.onMainImgLoad}
                onError={this.onMainImgError}
                onWinLoad={onWinLoad}
                alt={this.imageAlt}
              />
            </LazyLoad>
          </div>
          {!isDesktop && isPdpPage(domain) && styleId && (
            <PdpFavoriteComponentV2
              type="products"
              view="active"
              className="nw-pdp-left-fav-icon nw-pdp-productcarouselfavicon"
              showtext={false}
              id={styleId}
            />
          )}
          <ProductBtns.component
            index={index}
            promotions={promotions}
            isAllPromotions={isAllPromotions}
            mainImage={this.productMainImage}
            brandText={brandText}
            productTitle={productTitle}
            discountRange={discountRange}
            styleId={styleId}
            isNew={isNew}
            mrpRange={mrpRange}
            sellingPriceRange={sellingPriceRange}
            productTags={productTags}
            productUrl={link}
          />
        </div>
        <div className="nw-productview-info">
          {this.returnProductTag()}
          <h3 className="nw-productview-brandtxt">{brandText}</h3>
          <div className="nw-productview-producttitle">{productTitle}</div>
          <PriceBlock.component
            className="nw-productview-priceblk"
            discountRange={discountRange}
            mrpRange={mrpRange}
            sellingPriceRange={sellingPriceRange}
          />
          <ProductPromotions.component promotions={promotions} />
          <ColorsAvail.component moreColors={moreColors} />
        </div>
      </Anchor.component>
    );
  }
}

ProductView.wrappedComponent.defaultProps = {
  inStock: true,
  mainImage: null,
  altImage: null,
  isNew: false,
  imageColor: "transparent",
  moreColors: null,
  onWinLoad: false,
  origin: null,
  discountRange: {
    min: 0,
    max: 0
  },
  mrpRange: {
    min: 0,
    max: 0
  },
  sellingPriceRange: {
    min: 0,
    max: 0
  },
  title: "empty",
  productTags: [],
  identity: ""
};
ProductView.wrappedComponent.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired,
  title: PropTypes.string,
  onWinLoad: PropTypes.bool,
  origin: PropTypes.string,
  ProductStore: PropTypes.shape({
    setPdpData: PropTypes.func.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  inStock: PropTypes.bool,
  link: PropTypes.string.isRequired,
  mainImage: PropTypes.string,
  altImage: PropTypes.string,
  imageColor: PropTypes.string,
  brandText: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  discountRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  mrpRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  sellingPriceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAllPromotions: PropTypes.bool.isRequired,
  styleId: PropTypes.string.isRequired,
  moreColors: PropTypes.number,
  productTags: PropTypes.arrayOf(
    PropTypes.shape({
      tagUrl: PropTypes.string,
      tagText: PropTypes.string,
      tagTextColor: PropTypes.string
    })
  ),
  identity: PropTypes.string,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("ProductStore", "BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductView.wrappedComponent,
  component: ProductView
};

export default ProjectComponent;
