import { observable, computed } from "mobx";
import Actions from "./actions";
import Methods from "./methods";

class ProductStoreClass extends Actions {
  constructor(props) {
    super(props);
    this.collectionIdTemp = (props && props.collectionId) || "";
    this.typeTemp = (props && props.type) || "";
    this.brandDetailsTemp = (props && props.brandDetails) || null;
    this.bannerDetailsTemp=(props && props.bannerDetails) || {};
    this.ProductData = (props && props.ProductData) || null;
    this.PdpData = (props && props.PdpData) || null;
    this.sizeChartData = (props && props.sizeChartData) || null;
    this.isDesktopTemp = props && props.isDesktop;
  }
  @observable isDesktop = this.isDesktopTemp;
  @observable collectionId = this.collectionIdTemp;
  @observable type = this.typeTemp;
  @observable queryApplied = "";
  @observable callCount = 1;
  @observable isFetchingData = false;
  @observable disableFilters = false;
  @observable ProductData = this.ProductData || null;
  @observable PdpData = null;
  @observable bannerDetails=this.bannerDetailsTemp;
  // @observable recentlyViewedStyles = null;
  @observable brandDetails = this.brandDetailsTemp;
  @observable showMobileFilters = false;
  @observable tempFilters = null;
  @observable listingPageNo = 1;
  @observable
  mobileFilterToggle = {
    activeFilter: "",
    refine: false,
    sortBy: false,
  };
  @observable
  mobileTempUrlCache = {
    pathname: "",
    search: "",
  };
  @observable
  omniPincodeCache = {
    delivery_time: "",
    nearby_stores: "",
    radius: 20,
  };
  @observable sizeChartData = null;
  @observable gridActive = true;
  @observable blockActive = false;
  @observable isGridActiveClassName = "nw-productlist-grid-active";
  @observable isBlockActiveClassName = "";
  @observable triggerMobileApply = false;
  @observable sddDeliveryMode = false;
  @observable nddDeliveryMode = false;
  @observable standardDeliveryMode = false;
  @observable storePickData = [];
  @observable storePickUpIncentiveData = null;
  @observable autoSuggestionsData = {title: "", list: []};
  @observable autoSuggetsLoader = true;
  @observable suggestionTerm = "";

  @computed
  get isHavingMoreProducts() {
    if (
      this.ProductData &&
      this.ProductData.totalElements -
        this.ProductData.styles.styleList.length >
        0
    ) {
      return true;
    }

    return false;
  }
  @computed
  get pdpDetails() {
    return Methods.getPdpDetails(this.PdpData);
  }
  @computed
  get customPriceFilters() {
    const location =
      this.mobileTempUrlCache && this.mobileTempUrlCache.pathname
        ? this.mobileTempUrlCache
        : window.location;
    return Methods.checkIfCustomPriceFilters(location, this.ProductData);
  }
}

// const ProductStoreConst = new ProductStoreClass();

export default ProductStoreClass;