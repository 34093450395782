import { keycloak } from "./keycloak.js";
const isServer  = (global && global.isServer) || false;
const thorEndPoint = (isServer && process.env.REACT_APP_THOR) ? process.env.REACT_APP_THOR : process.env.REACT_APP_BACKEND_HOST;
const basePath = `${thorEndPoint}/api/`;
const basePathV2 = `${thorEndPoint}/apiV2/`;
const logInPath = `${process.env.REACT_APP_BLACKBOLT}/`;
const logmanPath = `${process.env.REACT_APP_LOGMAN_HOST}/`;
const jetrayPath = `${process.env.REACT_APP_JETRAY_HOST}/`;
const solusAiPath = `${process.env.REACT_APP_SOLUS_AI}`

const apis = {
  basePathV1: `${basePath}`,
  basePathV2: `${basePathV2}`,
  masterTemplates: `${basePathV2}masterTemplates`, // TODO!! to be changed back to basePathV2 once the development is completed
  getType: `${basePathV2}getType`,
  mobileHeader: `${basePath}header?type=website_homepage&platform=mobile`,
  desktopHeader: `${basePathV2}header`,
  filter: `${basePath}search/filters`,
  forgotPassword: `${logInPath}login/users/end-user/forgot-password`,
  footerLink: `${basePath}footerlinks?url=${window.location.hostname}`,
  storesList: `${basePath}searchFulfillmentCenters?isodin=2`,
  registerUser: `${basePath}blackbolt/register`,
  account: `${basePath}account`,
  mybag: `${basePath}mybag/v2`,
  mergeCart: `${basePath}myBag/v3`,
  mybagGuest: `${basePath}myBag/overview/v3`,
  myBagDetails: `${basePath}myBag/v3`,
  addToBag: `${basePath}myBag/v3`,
  removeFromBag: `${basePath}myBag/remove/v3`,
  socialLogin: `${logInPath}login/users/end-user/token/v2`,
  login: `${logInPath}login/users/end-user/token/v2`,
  loginV2:`${logInPath}login/users/end-user/v3/token`,
  brandList: `${basePath}search/filters`,
  brandListNew: `${basePath}storelocator/brands/v2`,
  allProductData: `${basePathV2}listing/products`,
  omniFiltersData: `${basePathV2}listing/omni`,
  listingDetails: `${basePathV2}listing/detailsV2`,
  resetPassword: `${logInPath}login/users/end-user`,
  token: `${logInPath}login/auth/api/v2/realms/${
    keycloak.realm
  }/protocol/openid-connect/token`,
  details: `${basePath}product/details`,
  getWishList: `${basePath}fav/v2`,
  setWishList: `${basePath}fav/v2/products`,
  getBrandWishList: `${basePath}favourite`,
  allFav: `${basePath}fav/v2`,
  setBrandWishList: `${basePath}favourite/brand/`,
  deleteWishList: `${basePath}fav/delete`,
  deleteFavProduct: `${basePath}fav/delete`,
  pincode: `${basePath}product/shippingAndStoreDetails`,
  sizeCharts: `${basePath}sizeCharts`,
  pincodeDetails: `${basePath}shipping/pincodeDetails`,
  addAddress: `${basePath}shipping/address`,
  shippingAddress: `${basePath}address/shippingAddress`,
  recentlyViewedStyles: `${basePath}product/recentlyViewedStyles`,
  multipleStyles: `${basePath}product/multipleStyles`,
  similarStyles: `${basePath}product/similarStyles`,
  checkout: `${basePathV2}checkout`,
  checkoutStores: `${basePath}shipping/v3/stores`,
  generateOtp: `${basePath}otp/generateOtp`,
  generateOtpV2: `${basePathV2}otp/generateOtp/v3`,
  generateRegisterOtpV2: `${basePathV2}otp/generateOtp/v2/registration`,
  resendOtp: `${basePath}otp/resendOtp`,
  resendOtpV2: `${basePathV2}otp/resendOtp/v3`,
  resendOtpV3: `${basePathV2}otp/resendOtp/v2/resend`,
  validateOtp: `${basePath}otp/validateOtp`,
  validateOtpV2: `${basePathV2}otp/validateOtp/v3`,
  addMoneyInWallet: `${basePathV2}payment/wallet/addMoney`,
  getPaymentMethods: `${basePath}payment/methods/v4`,
  getPaymentMethodsV6: `${basePathV2}payment/methods/v6`,
  cardBin: `${basePath}payment`,
  makePayment: `${basePath}payment/makePayment/v2`,
  makePaymentV2: `${basePathV2}payment/initiatePayment/v2`,
  postPayment: `${basePathV2}payment/validator`,
  loadPhonepe: `${basePath}payment/makePaymentPhonepe`,
  phonepeValidateVpa: `${basePath}payment/phonepe/validation/vpa`,
  orderPaymentPending:  `${basePath}payment/pending/phonepe`,
  orderDetails: `${basePath}orderDetails`,
  orderConfirm: `${basePathV2}order/confirmation`,
  validateCaptcha: `${basePath}validatecaptcha`,
  changePassword: `${logInPath}login/users/end-user`,
  updateUserInfo: `${basePath}account/userInfo`,
  savedCards: `${basePath}payment/savedCards`,
  deletePaymentCard: `${basePath}payment/deletePaymentCard`,
  returnsDetails: `${basePath}returns/v2`,
  makeReturn: `${basePath}returnInitiate`,
  addNEFT: `${basePathV2}/addNeftV2`,
  downloadReturnForm: `${logInPath}d/api/returns/form`,
  ProductOrderDetails: `${basePathV2}orderDetailsv2`,
  cancelDetails: `${basePath}cancel/details`,
  makeCancel: `${basePath}cancel/initiate`,
  editAddress: `${basePath}address/shippingaddress`,
  noResults: `${basePath}search/noResults`,
  brandNewArrivals: `${basePath}brand/newArrivals/`,
  brandOnSale: `${basePath}brand/onSale/`,
  whoWeAre: `${basePath}whoweare`,
  customerCare: `${basePath}customercare`,
  termsAndCondition: `${basePath}terms`,
  privacyPolicy: `${basePath}privacy`,
  contactUs: `${basePath}contactus`,
  dataToLogman: `${logmanPath}`,
  appLink: `${basePath}appDownloadLink`,
  moreInfo: `${basePathV2}loyalty/brandMoreInfo`,
  loyaltyCards: `${basePathV2}loyalty/getAllBrands`,
  multipleStylesDetails: `${basePathV2}product/multipleproducts`,
  favBlogs: `${basePath}favourite/blog`,
  loyaltyPointsData: `${basePathV2}loyaltyPointsData`,
  brandsList: `${basePathV2}brandListV2`,
  getpricingOverview: `${basePathV2}overview`,
  makeExchange: `${basePathV2}mail/trigger`,
  deliveryCharges: `${basePathV2}delivery_info?defaultRequired=true`,
  checkUserExist: `${logInPath}login/users/end-user/exists`,
  passwordRegex: `${basePathV2}passwordRegex`,
  registerWithOtp:`${logInPath}login/users/end-user/v3/register`,
  eventPublish:`${jetrayPath}event/publish`,
  registerWithOtp:`${logInPath}login/users/end-user/v3/register`,
  applicableCoupons: `${basePathV2}applicablecoupons`,
  autoSuggestion: `${basePathV2}autoSuggestions`,
  errorMail : `${basePathV2}errorMail`,
  errorMail : `${basePathV2}errorMail`,
  reviewConfig:`${basePath}reviews/config`,
  reviewImageUpload:`${basePath}reviews/images`,
  deleteReviewImage:`${basePath}reviews/images`,
  createReview:`${basePath}reviews`,
  fetchReview:`${basePath}reviews/fetch`,
  getCustomerPhotos: `${basePath}reviews/customerphotos`,
  fetchReviewWithId: `${basePath}reviews/fetchwithId`,
  fetchAllReviews: `${basePath}reviews/allreviews`,
  configAutoSuggestion : `${basePathV2}autoSuggestions/config`,
  fetchProductsBySku : `${basePathV2}fetchProductsBySku`,
  landingPageSolus: solusAiPath,
  saveOrderRatings: `${basePath}reviews/orderConfirmation`
};

export default { ...apis };
