import { keycloak } from "./keycloak.js";
const BRAND_NAME = "NNNOW";
const cdnBasePath = `https://${process.env.REACT_APP_CDNBASEPATH}`;

const brandFilterKeys = [
  "ABC",
  "DEF",
  "GHI",
  "JKL",
  "MNO",
  "PQR",
  "STU",
  "VWX",
  "YZ#"
];

const BREADCRUMB_HOME = "Home";
const test = "a";
const expiryTime = 1528897271083;
const filterInitialLength = 14;
const filterKeys = {
  Color: "f-co",
  Price: "f-pr",
  Occasion: "f-oc",
  Brand: "f-br",
  delivery_time: "f-dt"
};

const searchQuerySeparator = "--";
const subPromoSeparator = "__";
const priceSeparator = "-";
const FOOTER_TEXTS = {
  PRIVACY_POLICY: "Privacy Policy",
  TNC: "Terms & Conditions"
};
const footerUrls = {
  privacytext: "Privacy Policy",
  tnctext: "Terms & Conditions",
  privacypolicy: "/privacypolicy",
  termsconditions: "/termsconditions",
  whoweare: "/whoweare",
  customercare: "/customercare",
  contactus: "/contactus",
  facebook: "https://www.facebook.com/heynnnow",
  intagram: "https://www.instagram.com/heynnnow",
  twitter: "https://twitter.com/heynnnow",
  youtube: "https://www.youtube.com/channel/UCQ2fMb06LyWA0S5upVp2UJg"
};
const WHO_WE_ARE = {
  leftNav: {
    title: "NNNOW",
    navListItems: [
      { text: "About NNNOW", url: "#aboutnnnow" },
      {
        text: "About Arvind Fashions Limited",
        url: "#aboutarvindfashionslimited"
      }
    ]
  },
  rightSideContent: [
    {
      title: "1900s",
      content: "The Swadeshi Movement boycotts imported textiles"
    },
    {
      title: "1930s",
      content: "Three Lalbhai brothers set up a mill for fine fabric"
    },
    {
      title: "1990s",
      content: "Arvind becomes the world’s third-largest denim manufacturer"
    },
    {
      title: "2000s",
      content:
        "Arvind Mills renamed Arvind Limited. Vision: House of Brands Pioneers in bringing global fashion to India"
    },
    {
      title: "2013s",
      content:
        "Arvind Internet Ltd founded to enable Arvind’s e-commerce vision"
    },
    {
      title: "2014s",
      content:
        "Custom clothing experience Creyate launches, with you as designer"
    },
    {
      title: "2015s",
      content:
        "Online-only fast fashion labels SHUFFLE and PRYM enter the market"
    },
    { title: "2016s", content: "Est. since NNNOW" }
  ],
  mobileLeftNav: {
    title: "Browse :",
    navListItems: ["About NNNOW", "About Arvind Fashions Limited"]
  }
};

const CUSTOMER_CARE = {
  leftNav: {
    title: "Frequently asked questions",
    navListItems: [
      { text: "About NNNOW", url: "#aboutnnnow" },
      { text: "Your Account", url: "#youraccount" },
      { text: "Your Order", url: "#yourorder" },
      { text: "Delivery & tracking", url: "#deliverytracking" },
      { text: "Returns & Exchanges", url: "#returnsexchanges" },
      { text: "Cancellations", url: "#cancellations" },
      { text: "And More", url: "#andmore" }
    ]
  },
  mobileLeftNav: {
    title: "Browse :",
    navListItems: [
      "About NNNOW",
      "Your Account",
      "Your Order",
      "Delivery & tracking",
      "Returns & Exchanges",
      "Cancellations",
      "And More"
    ]
  }
};

const TERMS_AND_CONDITIONS = {
  title: "Terms & Conditions"
};

const PRIVACY_POLICY = {
  title: "Privacy Policy"
};

const CONTACT_US = {
  title: "Contact Us",
  message1: {
    message: "Call us",
    info: "080 4646 5500",
    detail: "Monday - Sunday (9am - 6pm)"
  },
  message2: {
    message: "Email us",
    info: "care@nnnow.com"
  },
  formTitle: "Send us a message",
  label1: "Your Name *",
  label2: "Your Email *",
  label3: "Subject *",
  label4: "Message *",
  label5: "SUBMIT",
  successMessage:
    "Yay! thanks for reaching out to us.\n We'll get back to you shortly.",
  invalidSubjectErrorMsg: "Subject must have only alphabets and numbers",
  minInputSubjectErrorMsg: "Plug in atleast 3 characters."
};
const CONTACT_US_V2 = {
  title: "CONTACT US",
  FAQ: "/customercare",
  message1: {
    message: "FREQUENTLY ASKED QUESTIONS",
    info: "Know everything you need to know about your order here"
  },
  message2: {
    message: "CALL US",
    info: "+91-8147493085",
    detail: "Monday - Sunday (9am - 6pm)",
    visibility: true
  },
  message3: {
    message: "EMAIL US",
    info: "care@nnnow.com",
    detail: "You will hear back from us within 24 hrs."
  },
  formTitle: "Send us a message",
  label1: "Your Name *",
  label2: "Your Email *",
  label3: "Subject *",
  label4: "Message *",
  label5: "SUBMIT",
  successMessage:
    "Yay! thanks for reaching out to us.\n We'll get back to you shortly.",
  invalidSubjectErrorMsg: "Subject must have only alphabets and numbers",
  minInputSubjectErrorMsg: "Plug in atleast 3 characters.",
  errorMsg: "Oops! Something went wrong."
};

const STATICPAGE_HEADER_COLOR_CLASSES = [
  "nw-whoweare-desktopcolor",
  "nw-customercare-desktopcolor",
  "nw-contactus-color",
  "nw-terms-color",
  "nw-privacy-color"
];

const facebook = {
  appId: process.env.REACT_APP_FACEBOOK,
  version: "v5.0",
  scope: "email",
  url: "//connect.facebook.net/en_US/all.js",
  xfbml: true
};
const gmail = {
  appId: process.env.REACT_APP_GMAIL,
  cookiepolicy: "single_host_origin",
  scope: "profile email"
};

const OAuth2 = {
  endPoint: "https://accounts.google.com/o/oauth2/v2/auth",
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirectURI: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
  cookiePolicy: "single_host_origin",
  scope: "profile email",
  fallBackRoute: "/"
};

const FACEBOOK_WEBVIEW = {
  endPoint: "https://www.facebook.com/v6.0/dialog/oauth",
  clientId: process.env.REACT_APP_FACEBOOK,
  redirectURI: process.env.REACT_APP_FACEBOOK_REDIRECT_URI,
  responseType: "token",
  errorRedirect: "error"
};

const pageType = {
  listing: "product-listing-anonymous",
  brands: "brands",
  pdp: "product-display",
  storeLocator: "store-locator",
  home: "home",
  searchPage: "search",
  myBag: "mybag",
  checkout: "checkout",
  shipping: "checkout",
  payment: "payment",
  orderConfirmation: "orderconfirmation",
  myAccount: "account",
  favourite: "favorites.shortlist",
  categoryTwoTemplate: "CategoryTemplateII",
  orderDetails: "orderdetails",
  resetPassword: "/reset-password",
  myOrders: "myorders",
  favorites: "favorites",
  getApp: "get-app",
  notFound: "notfound",
  brandslist: "brandslist"
};
const omniViewimageBasePath = `${cdnBasePath}/client/assets/images/omnipopup/`;

const scrollTime = {
  slow: 1000,
  fast: 100
};

const AFFILIATE_EXPIRY = 43200; // 2592000000 Milliseconds = 43200 mintues.
const blackBoltUrl = [
  "token",
  "login",
  "phase2",
  "registerUser",
  "forgotPassword"
];
const notToRetryURL = ["registerWithOtp", "loginV2", "resetPassword"];

const blackboltConfig = {
  APP_ERROR_MSG:
    "Due to suspicious activity we have blocked your IP. Please try again later.",
  ERROR_MSG: "Oops Something went wrong. Please try again later.",
  RESET_ERROR_MSG:
    "Sorry! the reset link is already used once. Please do forgot password again.",
  RESET_PASSWORD_MISMATCH: "Passwords don't match. Please enter same password.",
  RESET_PASSWORD_SUCCESS: "Yay! Password is updated, please login to continue.",
  RESET_PASSWORD_TEXT: "RESET PASSWORD",
  RESET_PASSWORD_NULL: "Enter your password",
  RESET_PASSWORD_LOGGEDIN: "Hey, You are already Logged-In!!",
  grant_type: "password",
  grant_type_Otp: "otp",
  email: "EMAIL",
  mobile: "SMS",
  sent: "SENT",
  socialGrantType: "social_token",
  google: "GOOGLE",
  facebook: "FACEBOOK",
  ERROR_CODE: ["409", "401", "404", "403", "400"],
  channel: "odin",
  CHANGE_INFO_SUCCESS: "Your changes have been saved .",
  FILL_MANDATORY_FIELDS: "Please fill all the mandatory fields",
  DEFAULT_CHECK_USER_ERROR_MSG: "User Profile Already Exists",
  ACCOUNT_LOCKOUT_CODE: "207"
};
const blackBoltVersion = "v2";
const requiredKeysForListing = ["q", "sort", "p-st", "p-dl", "r"];
const DEF_META_DATA = {
  title: "NNNOW - India's Leading Online Store For Top International Brands",
  description: "Online Shopping for Men, Women, and Kids. Buy a wide range of premium winter wear, casual wear, footwear, accessories & more at NNNOW.com. Get fast shipping & 15 day returns.",
  keywords:
    "Online shopping, Online shopping India, Men shopping, women online shopping, Shop for accessories, shop for clothing, kids online shopping, Arrow, Gap, Sephora, U. S. Polo Assn., Aeropostale, Gant, Nautica, Flying Machine, The Children’s Place, Ed Hardy, True Blue, Elle, Izod, Ruf & Tuf, Unlimited",
  imageURL: `${cdnBasePath}/client/assets/images/nnnow-logo-200.png`,
  pageURL: "https://www.nnnow.com",
  fb_appID: "383470072074863",
  google_site_verification: "lRXUhl40gQth49KLZ8fV8vv9fNisI9EXpef4Z1h4lzc",
  content: "NNNNOW"
};

const PDP_RETURNS = {
  returnPolicy:
    'How to return this item? Ship it back to NNNOW or have us pick it up within 15 days. Just keep it unwashed, undamaged and with tags intact so it passes our quality checks. See <a class="nw-pdp-desktopaccordiontnc" href="/customercare" target="_blank">FAQs</a> on our Returns Policy for more.',
  noReturnPolicy:
    "This item can’t be returned — our Returns Policy doesn’t cover innerwear, lingerie, fragrances and beauty products."
};
const PDP_CAROUSEL_DEFAULT_VIEWALL_TEXT = "VIEW ALL";
const PDP_CAROUSEL_SIZE = {
  XS: 2,
  SM: 2,
  MD: 3,
  LG: 5
};

const LOGMAN_SERVICE_NAME = "NNNOW";
const PDP_CAROUSEL_HEADINGS = {
  IN_THIS_LOOK: "COMPLETE THE LOOK",
  SIMILAR_PRODUCTS: "SIMILAR PRODUCTS",
  RECENTLY_VIEWED: "RECENTLY VIEWED"
};
const APP_BREAKPOINTS = {
  XS: 768,
  SM: 992,
  MD: 1200
};
const FB_SHARE_TITLE = "odin NNNOW";

const STORAGE_KEYS = {
  RECENTLY_VIEWED: "recentlyViewedItems",
  LAST_USED_PINCODE: "recentlyUsedPincode",
  UTM_MEDIUM: "utm_medium"
};

const CACHE_SETTINGS = {
  DONOT_CACHE: {
    "Cache-Control": "no-store, no-cache, must-revalidate"
  }
};

const HOME_PAGE_URL = process.env.REACT_APP_BASE_PATH_URL;
const DONOT_SHOW_OCCASION_FILTER_IDS = ["se"];
const PDP_CAROUSEL_MAX_LIMIT = 20;
const STATIC_GETTYPE_RESPONSE = {
  staticPage: true,
  documentType: null,
  type: ""
};

const ROUTE_PATHS = {
  LOYALTY: "/account#loyalty",
  ACCOUNT: "/account",
  CONTACTUS: "/contactus",
  FAVORITES: "/favorites",
  MYBAG: "/mybag",
  MYORDERS: "/myorders",
  STORELOCATOR: "/store-locator",
  SHORTLISTS: "/favorites/shortlists",
  LISTING: "/products"
};
const STATIC_HEADER_ROUTES = ["mybag", "checkout"];

const STATIC_ROUTES = [
  "product-listing",
  "products",
  "search",
  "store-locator",
  "mybag",
  "account",
  "checkout",
  "orderdetails",
  "myorders",
  "orderconfirmation",
  "favorites",
  "whoweare",
  "customercare",
  "termsconditions",
  "contactus",
  "privacypolicy",
  "noresults",
  "apps",
  "reset-password",
  "404",
  "apple-app-site-association",
  "brandslist"
];

const EXCLUDE_STORY_BACKGROUND_COLOR = [
  "homeMasterTemplate",
  "brandMasterTemplate"
];
const enableCollectionSSR = false;
const GETTYPE_PAGEWISE_VALUES = {
  UNKNOWN: ["unknown"],
  LISTING: ["Collection", "ProductListBanner", "Brand"],

  // TODO products to be pushed
  STATIC_LISTING_ROUTE: ["styles"],
  PDP: ["Style"],
  MASTER_TEMPLATE: [
    "HMT2",
    "homeMasterTemplate",
    "brandMasterTemplate",
    "collectionMasterTemplate",
    "categoryMasterTemplate",
    "BrandMasterTemplate2",
    "BrandMasterTemplate3",
    "GenericTemplate",
    "WdBlogMock",
    "MasterGenericTemplate",
    "BaseTemplate"
  ]
};
const SUPPORTED_GETTYPE_VALUES = [].concat(
  ...Object.keys(GETTYPE_PAGEWISE_VALUES).map(
    item => GETTYPE_PAGEWISE_VALUES[item]
  )
);
const FULL_WIDTH_MASTER_TEMPLATES = [
  "homeMasterTemplate",
  "brandMasterTemplate",
  "GenericTemplate",
  "WdBlogMock",
  "MasterGenericTemplate",
  "BaseTemplate"
];

const OLD_MASTER_TEMPLATES = [
  "homeMasterTemplate",
  "brandMasterTemplate",
  "collectionMasterTemplate",
  "categoryMasterTemplate"
];
const MASTER_TEMPLATE_COMPONENT_MAPPING_MOBILE = {
  leftNav: "LeftNavMobile"
};

const MY_BAG_OFFER_IMAGE = {
  imageLink: process.env.REACT_APP_MYBAGOFFERIMAGE,
  link: process.env.REACT_APP_MYBAGOFFERLINK
};

const MASTER_TEMPLATE_COMPONENT_MAPPING = {
  blocks: "Blocks",
  leftNav: "LeftNav",
  banner: "Banner",
  topBar: "TopBar",
  heroBanner: "TopBanner",
  topBanner: "TopBanner",
  youtubeVideo: "VideoComponent",
  sephoraCarousel: "BannerCarousel",
  gapCarousel: "BannerCarousel",
  bannerCarousel: "BannerCarousel",
  story: "StoryComponent",
  blogStory: "StoryComponent",
  tertiaryStorySection: "SectionCarousel",
  storyCarousel: "SectionCarousel",
  trendingTitle: "StoryTitle",
  secondaryStoriesTitle: "StoryTitle",
  primaryStories: "StoryMasonry",
  secondaryStories: "StoryMasonry",
  productCarousal: "ProductCarouselComponent",
  quickLinks: "QuickLinksComponent",
  dummy: "DummyComponent",
  brandStoryTitle: "BrandStoryTitle",
  brandStoryLogo: "BrandStoryLogo",
  brandStoryDescription: "BrandStoryDescription",
  NewArrival: "NewArrivalsCarousel",
  brandStory: "BrandStory",
  storyGrid: "StoryGrid",
  paraText: "ParaText",
  logoBand: "LogoBand",
  genericTextBand: "GenericTextBand",
  title: "Title",
  blogHeading: "BlogHeading",
  paddingComponent: "PaddingComponent"
};

const MASTER_TEMPLATE_COMPONENT_CONFIGS = {
  VIDEO: {
    MIN_HEIGHT: {
      DESKTOP: "400px",
      MOBILE: "250px"
    }
  },
  TOP_BAR: {
    SHOW_LOGO: ["brandMasterTemplate"],
    TIMEOUT: 3000,
    ADD_SUCCESS: "Brand Added Successfully",
    REMOVE_SUCCESS: "Brand Removed Successfully",
    LABELS: {
      FAVORITED: "favorited",
      UNFAVORITED: "unfavorited"
    }
  },
  LEFT_NAV: {
    HIDE: [
      "brandMasterTemplate",
      "BrandMasterTemplate2",
      "BrandMasterTemplate3",
      "HMT2"
    ]
  },
  STORY: {
    SHOW_BORDER: ["ga"]
  },
  PRODUCT_CAROUSEL: {
    ORIGIN: "master_template_product_carousel",
    API_LIMIT: 20,
    SIZES: {
      XS: 4,
      SM: 4,
      MD: 4,
      LG: 6
    }
  },
  BANNER_CAROUSEL: {
    ARROWS: {
      base: "icomoon-sephora_"
    },
    SCROLL_INTERVAL: 5000,
    TRANSITION_TIME: {
      SLOW: 600,
      QUICK: 600
    }
  },
  SECTION_CAROUSEL: {
    SCROLL_INTERVAL: 3000,
    TRANSITION_TIME: {
      SLOW: 600,
      QUICK: 600
    }
  }
};
const PASTEL_COLOR_SET = [
  "#b5dce1",
  "#d7e0b1",
  "#f4e0e9",
  "#cfdaf0",
  "#f4e3c9",
  "#f4d9d0"
];

const SOLID_COLOR_SET = [
  "#33cc66",
  "#ff3366",
  "#b2d235",
  "#2ba2e4",
  "#ffa500",
  "#ea0020"
];
const BRAND_SPECIFIC_CONFIGS = {
  se: ["showMasterTemplateBrushStroke"]
};

const LOGOUT_DELAY_TIME = 5000;
const STORE_CHECK_LOGOUT =
  "Dude! You are a staff! Not a customer. Logging out..";
const SOMETHING_FISHY = "Something went wrong, Logging out..";
const MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_DESKTOP = 33;
const MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_MOBILE = 43;
const PINCODE_LENGTH = 6;
const HEADER_HEIGHT = 100;
const OVERFLOW_HIDDEN = "hidden";
const OVERFLOW_AUTO = "auto";
const PIXEL_SCROLLED_LIMIT = 70;
const FOOTER_HIDDEN_PATHS = ["mybag", "store-locator"];
const STATIC_FOOTER_PATHS = ["checkout"];
const APP_PROMPT_KEY = "app";
const APP_PROMPT_VALUE = "show";
const APP_OS = "Android";
const APP_BROWSER = "Chrome";
const APP_PROMPT_MESSAGE = "Open with NNNOW app";
const APP_PROMPT_OK = "OPEN";
const APP_PROMPT_CANCEL = "CANCEL";
const APP_PROMPT_URL = "www.nnnow.com/";
const APP_IMAGE = `${cdnBasePath}/client/assets/images/nnnow-logo-beta_1.png`;

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const DOMAINREGEX = `^(?:\w+\:\/\/)?([^\/]+)(.*)$`; //eslint-disable-line
const PDP_SIZE_CHART_LABEL = "SIZE CHART";
const PDP_SIZE_CHART_LABELV2 = "Size Chart";
const PDP_SIZE_CHART_NOTE =
  "Actual measurement may vary marginally depending on the intended fit of the product.";
const PDP_SIZE_BLOCK_TITLE = "SIZE";
const PDP_QUANTITY_BLOCK_TITLE = "QTY";
const PDP_SIZE_MODAL_TITLE1 = "Select ";
const PDP_SIZE_MODAL_TITLE2 = "Size";
const PDP_SIZE_MODAL_TITLE3 = " to add to bag";
const PDP_SIZE_MODAL_SUBTEXT = "Not Sure? View";
const PDP_QUANTITY_WARNING_TEXT = "Please select a size"
const SEPHORA_BRANDS = [
  "FOREST ESSENTIALS",
  "Bobbi Brown",
  "DOLCE & GABBANA",
  "roberto cavalli",
  "Yves Saint Laurent",
  "NATIO",
  "Paco Rabanne",
  "ZIRH",
  "MONTBLANC",
  "stila",
  "Smashbox",
  "Oscar Blandi",
  "MAKE UP FOR EVER",
  "Versace",
  "Ermenegildo Zegna",
  "Sephora Collection",
  "MARC JACOBS",
  "Gucci",
  "Estee Lauder",
  "Clarins",
  "PERCY & REED",
  "PRADA",
  "Givenchy",
  "boscia",
  "Kenzo",
  "Issey Miyake",
  "CLINIQUE",
  "Narciso Rodriguez",
  "Lacoste",
  "Benefit Cosmetics",
  "Tangle Teezer",
  "GLAMGLOW",
  "Elizabeth Arden",
  "MUGLER",
  "MOSCHINO",
  "DAVIDOFF",
  "Salvatore Ferragamo",
  "TOM FORD",
  "Calvin Klein Fragrances",
  "Cartier",
  "VALENTINO",
  "AZZARO",
  "CoSTUME NATIONAL",
  "NINA RICCI",
  "COVER FX",
  "BURBERRY",
  "SEPHORA",
  "Omorovicza",
  "Votre",
  "Lancome",
  "O.P.I",
  "BVLGARI",
  "Dior",
  "SHISEIDO",
  "dunhill",
  "NYX",
  "FOREO",
  "BURT'S BEES",
  "Hugo Boss",
  "SOAP & GLORY",
  "GIORGIO ARMANI",
  "DIESEL",
  "RALPH LAUREN",
  "ELIE SAAB",
  "Michael Kors",
  "Carolina Herrera",
  "Chloe",
  "BECCA"
].map(p => p.toLowerCase());

const PINCODE_BLOCK_TITLE = "DELIVERY/STORE OPTIONS :";
const PINCODE_BLOCK_DESCRIPTION =
  "Enter your pincode to view delivery & store options";

const MYORDERS_MESSAGES = {
  NOORDER: "You have not placed any orders yet!",
  ERROR_MSG: "Error while fetching your orders!",
  TITLE: "MY ORDERS",
  NOT_LOGGED_IN: "Please Login To Continue",
  LOGIN: "LOGIN"
};
const COPYRIGHT_TEXT = (() => `© ${new Date().getFullYear()} NNNOW`)();

const ADD_NEFT_TITLE = "ADD NEW NEFT DETAILS";

const ADD_NEFT_FORM = {
  accHoldName: "Account Holders Name*",
  bankName: "Bank Name*",
  accNumber: "Account Number*",
  conAccNumber: "Confirm Account Number*",
  branchName: "Branch Name*",
  ifsc: "IFSC Code*",
  accTypeTitle: "Account Type",
  savingsAccType: "Savings",
  currentAccType: "Current",
  submitButton: "SAVE",
  savingsAccountNature: "SB",
  currentAccountNature: "CC",
  conAccNumErrMsg: "Please enter the same Account Number!",
  default_error_msg: "Oops Something went wrong. Please try again later.",
  minCharAccHoldNameErrMsg: "Name!",
  minCharbankNameErrMsg: "Bank Name!",
  minCharbranchNameErrMsg: "Branch Name!",
  invalidNameErrMsg: "Alphabets only, please",
  accNumberLengthLimit: 18
};

const PDP_PRODUCTVIEWBUTTON_TEXTS = {
  shop: "SHOP NNNOW",
  favourite: "FAVOURITE",
  newText: "NEW IN"
};

const LOGOUT_REDIRECT_PATHS = ["orderconfirmation"];

const GETAPP = {
  LABEL: "Get APP",
  DESKTOP_LABEL: "DOWNLOAD APP",
  PATH: "/apps"
};

const NO_RESULTS = {
  searchTitle: "0 results found for",
  searchPlaceholder: "Search for",
  productCarouselTitle1: "NEW ARRIVALS",
  productCarouselTitle2: "ON SALE",
  toastrMessage: "Please input something to search...",
  newArrivals: "/products?na&sort=newest",
  onSale: "/products?os",
  MIN_SIZE: 20
};
const HIDE_BRANDNAME = process.env.REACT_APP_HIDE_BRANDNAME === "true";

const BLANK_SEARCH_MESSAGE = "Please input something to search!";
const APPS = {
  TITLE: "NNNOW ON THE GO!",
  MESSAGE:
    "Download our top-rated app, made just for you! It's free, easy and smart.",
  NAME: "MOBILENUMBERFIELD",
  PLACEHOLDER: "Phone number(+91)",
  BUTTON_LABEL: "SEND APP LINK",
  SUCCESS_TITLE: "THANK YOU",
  SUCCESS_MESSAGE:
    "We’ve just sent a SMS to your phone with the link to download the app.",
  CUSTOMER_BLOCK_TITLE: "CUSTOMER FEEDBACKS",
  CUSTOMER_DETAILS: [
    {
      ID: 0,
      NAME: "HARIKRISHNAN R NAIR",
      MESSAGE:
        "Uninterrupted access to fashion on my own terms - where I want, when I want, how I want, also at the price I want. Nnnow has a sense of style and purpose compared to the other online e-commerce portals. Nnnow is clear, cool and connected."
    },
    {
      ID: 1,
      NAME: "GOKULNATH SUBRAMANIAN",
      MESSAGE:
        "Amazing app to buy all my favourite brands at one shot. Several filters make omni Channel buying possible across all categories. Will definitely keep coming back and buying more."
    }
  ],
  APP_STORE_PATH:
    "https://itunes.apple.com/us/app/nnnow-fashion-shopping-app/id1142544717?mt=8",
  PLAY_STORE_PATH:
    "https://play.google.com/store/apps/details?id=com.nnnow.arvind&hl=en"
};

const BRANDS_LIST = {
  aeropostale: 1,
  arrow: 1,
  arvind: 1,
  edhardy: 1,
  flyingmachine: 1,
  gant: 1,
  gap: 1,
  sephora: 1,
  stride: 1,
  thechildrensplace: 1,
  trueblue: 1,
  unlimited: 1,
  uspoloassn: 1,
  blog: 1,
  tommyhilfiger: 1,
  colehaan: 1,
  calvinklein: 1,
};

const FB_DOMAIN_VERIFICATION_KEYS = {
  ARROW: "dwwyoxttohi5gcdsj60mxotcz3l9i1",
  USPOLOASSN: "kibdejdfoceiynp4h62rlftdmf4t23",
  NNNOW: "cr9b3lhezitnlph6lpo55y2njc2y5b",
  SEPHORA: "azvqymz65psva1re8qtlfms0qhb0qz"
};

const GOOGLE_SITE_KEYS = {
  BRAND_JOURNEY: "JBWYYucvj4PnrGImaaidPF4vGYEGxgJocYwHNbE9_DY",
  DEFAULT_GOOGLE_KEY: "JBWYYucvj4PnrGImaaidPF4vGYEGxgJocYwHNbE9_DY"
};

// PdpBannerSlot

const PDP_BANNERSLOT_URL = "/products";
const PRODUCTLISTBANNER = "ProductListBanner";

// PdpNonReturnBlock

const PDP_NONRETURNBLOCK_TEXT1 = "RETURN POLICY ON THIS ITEM";
const PDP_NONRETURNBLOCK_TEXT2 = "This item is non-returnable.";

// Mobile Filter Button

const MOBILE_FILTER_BUTTON = {
  REFINE_BUTTON: "REFINE",
  SORT_BUTTON: "SORT",
  SCROLLX: 0,
  SCROLLY: 100
};
const IFRAME_LISTING_PARAMS = {
  catalogStatus: [
    "content_done",
    "image_done",
    "content_image_done",
    "content_image_pending"
  ]
};

const STATIC_META_TAG =
  "app-id=1142544717, app-argument=nnnow://url?url=https://";

const CONTINUE_LOGIN_MESSAGES = {
  OOPS: "OOPS!",
  NOT_LOGGED_IN: "Please Login To Continue",
  LOGIN: "LOGIN"
};

const INSTALL_PROMPT_COMPONENT = {
  TIME_DIFF: 60000
};

const NAV_BUTTON_CONFIG = {
  MAX_COUNT: 3,
  NODE_TYPE: "button_with_border"
};

const DEFAULT_GOOGLE_KEY = "DEFAULT_GOOGLE_KEY";

const EMAIL_SUBSCRIPTION = {
  ENTER_EMAIL: "Your Email",
  SEND_BTN_TEXT: "Send"
};

const NO_RESULT_PAGE = {
  NO_RESULTS: "Sorry!! No Results Found",
  YOUR_SEARCH: "Your search ",
  DID_NOT_FOUND_TEXT:
    " did not match any products. You may try again with different keywords."
};

const ARE_YOU_LOOKIG_FOR_TEXT = "Are you looking for ?";

const INITIAL_AUTOSUGGESTIONS = {
  v2: [
    {
      title: ARE_YOU_LOOKIG_FOR_TEXT,
      list: [
        ["", ""],
        ["", ""],
        ["", ""],
        ["", ""],
        ["", ""],
        ["", ""],
        ["", ""],
        ["", ""]
      ]
    }
  ],
  v1: [
    {
      title: ARE_YOU_LOOKIG_FOR_TEXT,
      list: [
        ["", ""],
        ["", ""],
        ["", ""]
      ]
    },
    {
      title: "",
      list: [
        ["", ""],
        ["", ""],
        ["", ""]
      ]
    }
  ]
};

const INVALID_AUTO_SUGGEST_INPUT = [
  {
    title: "invalid",
    list: [[`<div class="nw-noresult-text">Invalid input</div>`, ""]]
  }
];

const NORESULTS_CAROUSEL_SIZE = { XS: 2, SM: 2, MD: 2, LG: 2 };
const REVIEWS_DELIVERY_CONFIRMATION = ["CUSTOMERPICKCOMPLETE","DELIVERED","RETURNED"];
const SEPHORA_BRAND_ID = "se";
export {
  IFRAME_LISTING_PARAMS,
  HIDE_BRANDNAME,
  BRAND_NAME,
  BREADCRUMB_HOME,
  FOOTER_TEXTS,
  COPYRIGHT_TEXT,
  DOMAINREGEX,
  STATIC_FOOTER_PATHS,
  FOOTER_HIDDEN_PATHS,
  ROUTE_PATHS,
  STATIC_HEADER_ROUTES,
  STATIC_GETTYPE_RESPONSE,
  OLD_MASTER_TEMPLATES,
  SUPPORTED_GETTYPE_VALUES,
  EXCLUDE_STORY_BACKGROUND_COLOR,
  FULL_WIDTH_MASTER_TEMPLATES,
  BRAND_SPECIFIC_CONFIGS,
  PASTEL_COLOR_SET,
  MASTER_TEMPLATE_COMPONENT_CONFIGS,
  MASTER_TEMPLATE_COMPONENT_MAPPING_MOBILE,
  MASTER_TEMPLATE_COMPONENT_MAPPING,
  STATIC_ROUTES,
  GETTYPE_PAGEWISE_VALUES,
  DONOT_SHOW_OCCASION_FILTER_IDS,
  PDP_CAROUSEL_MAX_LIMIT,
  CACHE_SETTINGS,
  STORAGE_KEYS,
  FB_SHARE_TITLE,
  APP_BREAKPOINTS,
  PDP_CAROUSEL_DEFAULT_VIEWALL_TEXT,
  PDP_CAROUSEL_HEADINGS,
  PDP_CAROUSEL_SIZE,
  LOGMAN_SERVICE_NAME,
  keycloak,
  PDP_RETURNS,
  subPromoSeparator,
  DEF_META_DATA,
  requiredKeysForListing,
  scrollTime,
  omniViewimageBasePath,
  pageType,
  brandFilterKeys,
  test,
  filterKeys,
  searchQuerySeparator,
  priceSeparator,
  footerUrls,
  gmail,
  facebook,
  filterInitialLength,
  SOLID_COLOR_SET,
  blackBoltUrl,
  notToRetryURL,
  blackBoltVersion,
  blackboltConfig,
  expiryTime,
  HOME_PAGE_URL,
  LOGOUT_DELAY_TIME,
  STORE_CHECK_LOGOUT,
  SOMETHING_FISHY,
  MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_DESKTOP,
  MAX_TITLEDESCRIPTIONHORIGENTAL_CHARECTER_MOBILE,
  PINCODE_LENGTH,
  HEADER_HEIGHT,
  OVERFLOW_HIDDEN,
  OVERFLOW_AUTO,
  PIXEL_SCROLLED_LIMIT,
  APP_BROWSER,
  APP_OS,
  APP_PROMPT_KEY,
  APP_PROMPT_VALUE,
  APP_PROMPT_MESSAGE,
  APP_IMAGE,
  APP_PROMPT_OK,
  APP_PROMPT_CANCEL,
  APP_PROMPT_URL,
  AFFILIATE_EXPIRY,
  MONTHS,
  PINCODE_BLOCK_TITLE,
  PINCODE_BLOCK_DESCRIPTION,
  ADD_NEFT_TITLE,
  ADD_NEFT_FORM,
  MYORDERS_MESSAGES,
  MY_BAG_OFFER_IMAGE,
  PDP_SIZE_CHART_LABEL,
  PDP_SIZE_CHART_LABELV2,
  PDP_SIZE_BLOCK_TITLE,
  PDP_QUANTITY_BLOCK_TITLE,
  PDP_SIZE_MODAL_TITLE1,
  PDP_SIZE_MODAL_TITLE2,
  PDP_SIZE_MODAL_TITLE3,
  PDP_SIZE_MODAL_SUBTEXT,
  PDP_QUANTITY_WARNING_TEXT,
  SEPHORA_BRANDS,
  LOGOUT_REDIRECT_PATHS,
  PDP_PRODUCTVIEWBUTTON_TEXTS,
  GETAPP,
  NO_RESULTS,
  WHO_WE_ARE,
  CUSTOMER_CARE,
  CONTACT_US,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  STATICPAGE_HEADER_COLOR_CLASSES,
  BLANK_SEARCH_MESSAGE,
  APPS,
  BRANDS_LIST,
  GOOGLE_SITE_KEYS,
  FB_DOMAIN_VERIFICATION_KEYS,
  PDP_BANNERSLOT_URL,
  PRODUCTLISTBANNER,
  MOBILE_FILTER_BUTTON,
  PDP_SIZE_CHART_NOTE,
  CONTACT_US_V2,
  STATIC_META_TAG,
  enableCollectionSSR,
  PDP_NONRETURNBLOCK_TEXT1,
  PDP_NONRETURNBLOCK_TEXT2,
  CONTINUE_LOGIN_MESSAGES,
  INSTALL_PROMPT_COMPONENT,
  NAV_BUTTON_CONFIG,
  OAuth2,
  DEFAULT_GOOGLE_KEY,
  FACEBOOK_WEBVIEW,
  EMAIL_SUBSCRIPTION,
  NO_RESULT_PAGE,
  INITIAL_AUTOSUGGESTIONS,
  INVALID_AUTO_SUGGEST_INPUT,
  NORESULTS_CAROUSEL_SIZE,
  REVIEWS_DELIVERY_CONFIRMATION,
  SEPHORA_BRAND_ID
};

export { default as productTags } from "./productTags.js";
export { default as URL_CONFIG } from "./urlConfig";
export { default as NOT_FOUND } from "./notFound";
export { default as Endpoints } from "./endPoints.js";
import * as Checkout from "./checkout.js";
export { default as MyBag } from "./myBag.js";
import * as OrderConfirmation from "./orderConfirmation.js";
import * as Payment from "./payment.js";
export { default as Promotions } from "./promotions.js";
import * as Login from "./login.js";
import * as RegexMethods from "./regex.js";
import * as MASTERTEMPLATE from "./masterTemplate.js";
export { default as PRICINGOVERVIEW } from "./pricingOverview.js";
export { default as PAYMENTPAGE } from "./payment.js";
import * as RETURNS from "./returns.js";
import * as CANCEL from "./cancel.js";
import * as ORDERDETAILS from "./orderDetails.js";
import * as MYACCOUNTPAGE from "./myaccount.js";
import * as FAVORITESPAGE from "./favorites.js";
import * as MAIN_CONFIG from "./generalConfigs.js";
export { default as PDP_CONFIG } from "./pdp.js";
export { default as SHOP_MULTILPE } from "./multiShop";
export { default as FAVNSHARE } from "./favAndShare";
export { default as FOOTER } from "./footer";
export { default as LISTING } from "./listing";
import * as Loyalty from "./loyalty.js";
export { default as HEADER } from "./header";
export { default as BRANDSLIST } from "./brandslist.js";
export { default as META } from "./meta.js";
import * as CACHINGVALS from "./caching";
export { default as STORE_LOCATOR_TIMINGS } from "./storelocator.js";
export {
  Checkout,
  OrderConfirmation,
  Payment,
  Login,
  RegexMethods,
  MASTERTEMPLATE,
  RETURNS,
  CANCEL,
  ORDERDETAILS,
  MYACCOUNTPAGE,
  FAVORITESPAGE,
  MAIN_CONFIG,
  Loyalty,
  CACHINGVALS,
  ARE_YOU_LOOKIG_FOR_TEXT
};
export { default as PRICE_INFO_BLOCK } from "./priceInfoBlock.js";
export { default as REVIEW } from "./ratingAndReview.js";
