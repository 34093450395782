import loadable from '@loadable/component';

if (typeof require.ensure !== "function")
    require.ensure = (d, c) => {
        c(require);
    };
if (typeof require.include !== "function") require.include = () => { };

const Login = loadable(() => import( /* webpackChunkName: "Login" */"../component/Login"));

const MobileAppPrompt = loadable(
    () => import( /* webpackChunkName: "MobileAppPrompt" */ "../component/MobileAppPrompt"));
const Listing = loadable(
    () => import( /* webpackChunkName: "Listing" */"../pages/Listing"));
const MyOrders = loadable(
    () => import( /* webpackChunkName: "MyOrders" */"../pages/MyOrders"));
const OrderDetails = loadable(
    () => import( /* webpackChunkName: "OrderDetails" */"../pages/OrderDetails"));
const Pdp = loadable(
    () => import( /* webpackChunkName: "Pdp" */"../pages/Pdp"));
const MyBag = loadable(
    () => import( /* webpackChunkName: "MyBag" */"../pages/MyBag"));
const Checkout = loadable(
    () => import( /* webpackChunkName: "Checkout" */"../pages/Checkout"));
const OrderConfirmation = loadable(
    () => import( /* webpackChunkName: "OrderConfirmation" */"../pages/OrderConfirmation")
);
const MyAccount = loadable(
    () => import( /* webpackChunkName: "MyAccount" */"../pages/MyAccount")
);
const StoreLocator = loadable(
    () => import( /* webpackChunkName: "StoreLocator" */"../pages/StoreLocator")
);
const NoResultsV2 = loadable(
    () => import( /* webpackChunkName: "NoResultsV2" */"../pages/NoResultsV2")
);
const BrandPage = loadable(
    () => import( /* webpackChunkName: "BrandPage" */"../pages/BrandPage")
);
const Favorites = loadable(
    () => import( /* webpackChunkName: "Favorites" */"../pages/Favorites")
);
const WhoWeAre = loadable(
    () => import( /* webpackChunkName: "WhoWeAre" */"../pages/WhoWeAre")
);
const CustomerCare = loadable(
    () => import( /* webpackChunkName: "CustomerCare" */"../pages/CustomerCare")
);
const TermsAndConditions = loadable(
    () => import( /* webpackChunkName: "TermsAndConditions" */"../pages/TermsAndConditions")
);
const PrivacyPolicy = loadable(
    () => import( /* webpackChunkName: "PrivacyPolicy" */"../pages/PrivacyPolicy")
);
const ContactUs = loadable(
    () => import( /* webpackChunkName: "ContactUs" */"../pages/ContactUs/V2")
);
const Apps = loadable(
    () => import( /* webpackChunkName: "Apps" */"../pages/Apps")
);
const NotFound = loadable(
    () => import( /* webpackChunkName: "NotFound" */"../pages/NotFound")
);
const BrandsList = loadable(
    () => import( /* webpackChunkName: "BrandsList" */"../pages/BrandsList")
);
const AllReviews = loadable(
    () => import( /* webpackChunkName: "BrandsList" */"../pages/AllReviews")
);

const SolusAI = loadable(
    () => import( /* webpackChunkName: "BrandsList" */"../pages/SolusAI")
);

export {
    Login,
    MobileAppPrompt,
    Listing,
    MyOrders,
    OrderDetails,
    Pdp,
    MyBag,
    Checkout,
    OrderConfirmation,
    MyAccount,
    StoreLocator,
    NoResultsV2,
    BrandPage,
    Favorites,
    WhoWeAre,
    CustomerCare,
    TermsAndConditions,
    PrivacyPolicy,
    ContactUs,
    Apps,
    NotFound,
    BrandsList,
    AllReviews,
    SolusAI
};