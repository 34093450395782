import { PAYMENTPAGE } from "npmlinks-constants";
import { gethostdomainpath, queryParamsToObject } from "./url";

const paymentBaseRequest = ({
  bankCode = null,
  description,
  saveCard = false,
  savedCardPG = null,
  savedCardToken = null,
}) => ({
  bankCode,
  description,
  saveCard,
  savedCardPG,
  savedCardToken,
});
const isPaymentOptionsNotAvailable = paymentResponse => {
  const { savedCards, paymentOptions } = paymentResponse;
  return !savedCards.length && !paymentOptions.length;
};

const setSurlFurl = (paramsTemp, orderId) => {
  // TODO:: once we make odin2 to master it should support api end point from any external cal
  let url;
  let newUrl;
  const domainName = window.location.host;
  const param = Object.assign({}, paramsTemp);
  // going forword we can hit the thor directly no intermidiate server required between Payu and Thor if we can provide redirect url with proper domain
  if (param.info.pgPName === "furl" || param.info.pgPName === "surl") {
    url = param.info && param.info.pgPValue;
    const { path } = gethostdomainpath(url);
    // NOTE : for making code backword compatible-- in new Url   we will  get  orderId from heimdall  itself
    const orderIdPassedFromHeimdall = path.substring(path.lastIndexOf("/") + 1);
    if (
      orderIdPassedFromHeimdall &&
      ["success", "failure"].indexOf(orderIdPassedFromHeimdall) === -1
    ) {
      newUrl = `${path}`;
    } else {
      newUrl = `${path}${orderId}`;
    }
    param.info.pgPValue = `${location.protocol}//${domainName}${newUrl}`;
  }
  return param;
};

const validCvvLength = cardType =>
  cardType === "AMEX"
    ? PAYMENTPAGE.CARD.CVVLENGTH.AMEX
    : PAYMENTPAGE.CARD.CVVLENGTH.DEFAULT;

const checkIfAllareStorePick = cartItems => {
  let allStorePicks = true;
  cartItems.forEach(cartItem => {
    if (cartItem.shippingMethod !== "STOREPICK") {
      allStorePicks = false;
    }
  });
  return allStorePicks;
};

const decidePG = paymentObj => {
  const loadPG = paymentObj && paymentObj.transactions && paymentObj.transactions.banking && Object.keys(paymentObj.transactions.banking).length > 0;
  return {
    loadPG,
    PGName: loadPG && paymentObj.transactions && paymentObj.transactions.banking.requestedPG || PAYMENTPAGE.PAYMENT_GATEWAYS.PAYU,
  }
}

const formatPGparamsRP = (requestParameters) => {
  let razorpayrequest = {};
  requestParameters
    .filter(
      element => PAYMENTPAGE.RAZORPAY_DATA[element.key]
    )
    .forEach(
      element => razorpayrequest[element.key] = element.info.pgPValue
    );
  return razorpayrequest;
}

const formatPGparamsPP = (requestParameters) => {
  let phonepeRequest = {};
  requestParameters
    .filter(
      element => PAYMENTPAGE.PHONEPE_DATA[element.key]
    )
    .forEach(
      element => phonepeRequest[element.key] = element.info.pgPValue
    );
  return phonepeRequest;
}

const createRPCardRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    CCNAME,
    CCNUM,
    CCEXPM,
    CCEXPYR,
    CCVV,
    AMOUNT: amount,
    RAZORPAY_CUSTOMER_ID,
    OFFERKEY: offer_id
  } = data;
  const cardObject = {
    amount,
    email,
    contact,
    order_id,
    method,
    'card[name]': CCNAME,
    'card[number]': CCNUM,
    'card[cvv]': CCVV,
    'card[expiry_month]': CCEXPM,
    'card[expiry_year]': CCEXPYR,
    offer_id
  }
  if (RAZORPAY_CUSTOMER_ID) {
    Object.assign(cardObject,
      {
        customer_id: RAZORPAY_CUSTOMER_ID,
        save: 1
      });
  }
  return cardObject;
};

const createRPSavedCardRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    CCVV,
    AMOUNT: amount,
    RAZORPAY_CUSTOMER_ID,
    SAVE_CARD_TOKEN,
    OFFERKEY: offer_id
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
    'card[cvv]': CCVV,
    customer_id: RAZORPAY_CUSTOMER_ID,
    token: SAVE_CARD_TOKEN,
    consent_to_save_card: 1,
    offer_id
  };
};

const createRPNetbankingRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    AMOUNT: amount,
    ENFORCE: bank,
    OFFERKEY: offer_id
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
    bank,
    offer_id
  }
};

const createRPCardlessEmiRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    AMOUNT: amount,
    OFFERKEY: offer_id
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
    provider: "zestmoney",
    offer_id
  }
};

const createRPUpiRequest = (data, method, vpa) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    AMOUNT: amount,
    OFFERKEY: offer_id
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
    vpa,
    offer_id
  }
};

const createRPGpayRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    AMOUNT: amount,
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
  }
};

const createRPWalletRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    AMOUNT: amount,
    ENFORCE: wallet,
    OFFERKEY: offer_id
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
    wallet,
    offer_id
  }
};

const convertToPaise = (amount) => {
  try {
    return Math.floor(amount * 100);
  } catch (err) {
    return amount;
  }
}

const createPPNetbankingRequest = (data, method) => {
  const {
    AMOUNT: amount,
    MERCHANT_TRANSACTION_ID: merchantTransactionId,
    MERCHANT_USER_ID: merchantUserId,
    PENDINGURL : redirecturl,
    CALL_BACK_URL: callbackurl,
    REDIRECT_MODE: redirectMode,
    PAYMENT_INSTRUMENT_TYPE: paymentType,
    BANK: bankCode,
    MOBILE: mobileNumber,
  } = data;
  return {
    merchantTransactionId,
    amount: parseInt(amount, 10),
    redirecturl,
    redirectMode,
    callbackurl,
    paymentType,
    bankCode,
    mobileNumber,
    merchantUserId
  }
};

const createPPCardRequest = (data, method) => {
  const {
    CCNAME,
    CCNUM,
    CCEXPM,
    CCEXPYR,
    CCVV,
    saveCard,
    merchantTransactionId,
    redirecturl,
    callbackurl,
    merchantUserId,
    AMOUNT: amount
  } = data;
  const cardObject = {
    amount,
    savecard: saveCard,
    phonePeCardDetails:{
    encryptedCardNumber: CCNUM,
    cardHolderName: CCNAME,
    expiryMonth: CCEXPM,
    expiryYear: CCEXPYR,
    encryptedCvv: CCVV,
    merchantTransactionId,
    redirecturl,
    callbackurl,
    merchantUserId,
    }
  }
  return cardObject;
};

const createPPSavedCardRequest = (data, method) => {
  const {
    EMAIL: email,
    PHONE: contact,
    RAZORPAY_ORDER_ID: order_id,
    CCVV,
    AMOUNT: amount,
    RAZORPAY_CUSTOMER_ID,
    SAVE_CARD_TOKEN,
  } = data;
  return {
    amount,
    email,
    contact,
    order_id,
    method,
    'card[cvv]': CCVV,
    customer_id: RAZORPAY_CUSTOMER_ID,
    token: SAVE_CARD_TOKEN,
    savecard: 1
  };
};

const createPPUpiRequest = (data, method, vpa) => {
  const {
    AMOUNT: amount,
    MERCHANT_TRANSACTION_ID: merchantTransactionId,
    MERCHANT_USER_ID: merchantUserId,
    PENDINGURL : redirecturl,
    CALL_BACK_URL: callbackurl,
    REDIRECT_MODE: redirectMode,
    PAYMENT_INSTRUMENT_TYPE: paymentType,
    MOBILE: mobileNumber,
    BANK: bankCode
  } = data;
  return {
    merchantTransactionId,
    amount: parseInt(amount, 10),
    redirecturl,
    redirectMode,
    callbackurl,
    paymentType,
    mobileNumber,
    merchantUserId,
    vpa,
    bankCode
  }
};

const razorpayConstruncts = {
  createRPNetbankingRequest,
  createRPCardRequest,
  createRPUpiRequest,
  createRPWalletRequest,
  createRPSavedCardRequest,
  createRPCardlessEmiRequest,
  createRPGpayRequest
}

const PhonePeConstructs = {
  createPPNetbankingRequest,
  createPPCardRequest,
  createPPUpiRequest,
  createPPSavedCardRequest
};

export {
  paymentBaseRequest,
  setSurlFurl,
  validCvvLength,
  checkIfAllareStorePick,
  isPaymentOptionsNotAvailable,
  decidePG,
  formatPGparamsRP,
  formatPGparamsPP,
  convertToPaise,
  razorpayConstruncts,
  PhonePeConstructs
};
