import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import GTM from "../../../analytics/GTM";
import { Endpoints, PDP_SIZE_MODAL_TITLE2 } from "npmlinks-constants";
import ProductRightPanel from "../ProductRightPanel";
import PdpFitGuide from "../../PdpComponents/PdpFitGuide";
import PdpNonReturnBlock from "../../PdpComponents/PdpNonReturnBlock";
import { calculateColors } from "../ProductUtil";
import {
  GridContainer,
  GridRow,
  GridColumn,
  Modal,
  Img,
  Toastr,
} from "arv-reactcomponents";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { isIframe, postIframeMessage } from "../../../utils";

const DEFAULT_ADD_ITEM_COUNT = 1;

@inject("ProductStore", "MybagStore")
@observer
class ProductModal extends Component {
  constructor(props) {
    super(props);
    const mainImage = props.mainImage.replace(/\/medium\//, "/large/");
    const {
      discountRange,
      mrpRange,
      sellingPriceRange,
      isNew,
      brandText: brandName,
      productTitle: name,
      returnable,
    } = props;
    this.state = {
      mainImage,
      selectedSku: {},
      ProductLoader: true,
      fitGuideModal: false,
      preventfix: false,
      selectedQty: 1

    };
    this.pdpDetails = {
      discountRange,
      mrpRange,
      sellingPriceRange,
      isNew: isNew || false,
      inStock: false,
      colors: {},
      skus: [],
      promotions: [],
      styleId: props.styleId,
      brandName,
      name,
      returnable: returnable || undefined,
    };
    this.defaultMainImage = mainImage;
    this.setStyle = this.setStyle.bind(this);
    this.setMainImage = this.setMainImage.bind(this);
    this.selectSku = this.selectSku.bind(this);
    this.addToBag = this.addToBag.bind(this);
    this.closeFitGuideModal = this.closeFitGuideModal.bind(this);
    this.fitGuideModal = this.fitGuideModal.bind(this);
    this.callFitGuide = this.callFitGuide.bind(this);
  }

  componentWillMount() {
    this.getProductDetails();
  }

  get isNewClassName() {
    return this.pdpDetails.isNew ? "" : "nwc-hidden";
  }

  // get isInStock() {
  //   const { ProductLoader } = this.state;
  //   const { inStock } = this.pdpDetails;
  //   if (ProductLoader) {
  //     return <p className="nw-product-stock nwc-contentloader" />;
  //   }
  //   return inStock ? (
  //     <p className="nw-product-stock">In Stock</p>
  //   ) : (
  //     <p className="nw-product-stock">Out Of Stock</p>
  //   );
  // }

  getProductDetails() {
    const { styleId } = this.pdpDetails;
    this.props.ProductStore.getDetails(styleId).then(res => {
      const { colors: pdpColors, mainStyle } = res;
      const {
        skus,
        images,
        inStock,
        colorDetails,
        discountRange,
        mrpRange,
        isNew,
        sellingPriceRange,
        promotions,
        brandName,
        name,
        quantity,
        url,
        sizeChartId,
        sizeRecommendation,
        productTags,
        returnable,
        gender,
        tenantSizeLabel,
        tenantsellableQty,
        pcmArticleType
      } = mainStyle;
      let image = null;
      let mainImage = Endpoints.noImg;
      if (images) {
        [image] = images;
        mainImage = image.large;
      }
      const colors = calculateColors(pdpColors, colorDetails);
      this.pdpDetails = {
        skus,
        colors,
        inStock,
        discountRange,
        mrpRange,
        sellingPriceRange,
        isNew,
        promotions,
        brandName,
        image,
        name,
        quantity,
        url,
        colorDetails,
        styleId,
        sizeChartId,
        sizeRecommendation,
        productTags,
        returnable,
        gender,
        tenantSizeLabel,
        tenantsellableQty,
        pcmArticleType
      };
      let stateUpdate = {
        ProductLoader: false,
        mainImage,
      };
      if (skus && skus.length === 1) {
        const [selectedSku] = skus;
        stateUpdate = {
          ...stateUpdate,
          selectedSku,
        };
      }
      this.callFitGuide(sizeChartId);
      this.setState(stateUpdate);
    });
  }

  setStyle(e, styleId, mainImage) {
    e.preventDefault();
    Analytics.trackEvent({
      category: Event.category.PRODUCT_QUICKVIEW,
      action: Event.action.COLOR_CHANGE,
    });
    this.defaultMainImage = mainImage;
    this.pdpDetails = {
      ...this.pdpDetails,
      styleId,
    };
    this.setState({ mainImage, ProductLoader: true, selectedSku: {} }, () => {
      this.getProductDetails();
    });
  }

  setMainImage(mainImage) {
    if (!mainImage) {
      const { defaultMainImage } = this;
      this.setState({ mainImage: defaultMainImage });
    } else {
      this.setState({ mainImage });
    }
  }

  selectSku(sku) {
    Analytics.trackEvent({
      category: Event.category.PRODUCT_QUICKVIEW,
      action: Event.action.SIZE_CHANGE,
      label: sku.skuId,
    });
    this.setState(state => {
      if (sku && !sku.qty) {
        sku.qty = DEFAULT_ADD_ITEM_COUNT; // eslint-disable-line
      }
      return {
        ...state,
        selectedSku: sku,
        selectedQty: 1
      };
    });
  }

  callFitGuide(sizeChartId) {
    if (sizeChartId) {
      this.props.ProductStore.getFitGuideDtails(sizeChartId);
    }
  }

  setSelectedQty = newQty => {
    if (newQty !== this.state.selectedQty) {
      this.setState({ selectedQty: newQty });
    }
  }

  addToBag() {

    const {
      brandName,
      image,
      name,
      quantity,
      url,
      promotions,
      colorDetails,
      inStock,
      gender,
      tenantSizeLabel,
      pcmArticleType
    } = this.pdpDetails;
    const { secondaryColor, primaryColor } = colorDetails;
    const { selectedSku, selectedQty } = this.state;
    const { styleId } = this.pdpDetails;
    const product = {
      skuId: selectedSku.skuId,
      brandName,
      image: (image && image.thumbnail) || Endpoints.noImg,
      mrp: selectedSku.mrp,
      size: selectedSku.size,
      name,
      price: selectedSku.price,
      grossSP: selectedSku.price,
      qty: selectedQty,
      url,
      sellableQty: selectedSku.sellableQuantity,
      styleId,
      discountInPercentage: selectedSku.discountInPercentage || 0,
      promotions,
      color: secondaryColor || primaryColor,
      inStock,
      gender,
      quantity,
      pcmArticleType: pcmArticleType || ""
    };
     
    let itemsArray = [{
      item_id: styleId,
      item_name: name,
      coupon: promotions,
      discount: selectedSku.discountInPercentage || 0,
      item_brand: brandName,
      item_category2: gender,
      price: selectedSku.price,
      item_variant: secondaryColor || primaryColor,
      quantity: this.state.selectedQty
    }];

    let ecommObj = {
      currency: "INR",
      value: selectedSku.price,
      items: itemsArray
    }

    const sizeTitle = tenantSizeLabel || PDP_SIZE_MODAL_TITLE2;

    if (selectedSku && selectedSku.skuId) {
      Analytics.trackEvent({
        category: Event.category.PRODUCT_QUICKVIEW,
        action: Event.action.ADD_TO_BAG_SUCCESS,
      });
      GTM.pushWithEvent(
        product,
        Event.GTMEvent.ADD_PRODUCT,
      );
      GTM.clearEcommDataLayer();
      GTM.pushObject({
        "ecommerce": ecommObj,
        "event": Event.GTMEvent.ADD_PRODUCT,
      });
      if (isIframe) {
        postIframeMessage({ type: "iframeAddToBag", data: product });
      } else {
        this.props.MybagStore.addToBag(product);
      }
      this.props.closeModal();
    } else {
      Analytics.trackEvent({
        category: Event.category.PRODUCT_QUICKVIEW,
        action: Event.action.ADD_TO_BAG_ISSUE,
      });
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: `Hold On! What ${sizeTitle} Do You Want?`,
        timeout: 3000,
      });
    }
  }

  fitGuideModal(flag) {
    Analytics.trackEvent({
      action: Event.action.FIT_GUIDE_CLICK,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE,
    });
    this.setState({ fitGuideModal: true, preventfix: !!flag });
  }

  closeFitGuideModal() {
    this.setState({ fitGuideModal: false, preventfix: false });
  }

  render() {
    const {
      mainImage,
      ProductLoader,
      selectedSku,
      fitGuideModal,
      preventfix,
      selectedQty
    } = this.state;
    const {
      brandName,
      name,
      sizeChartId,
      sizeRecommendation,
      returnable,
    } = this.pdpDetails;
    console.log("check ", this.pdpDetails);
    const { skuId = "" } = selectedSku;
    return (
      <div>
        <GridContainer className="nw-qview">
          <GridRow>
            <GridColumn className="nwc-grid-col-xs nw-qview-left">
              <div className="nw-qview-image">
                <Img src={mainImage} className="nw-qview-img" />
              </div>
              {!returnable &&
                returnable !== undefined &&
                process.env.REACT_APP_RETURNPOLICY_ENABLED === "true" ? (
                  <div className="nw-product-nonreturnable">
                    <PdpNonReturnBlock.component />
                  </div>
                ) : null}
            </GridColumn>
            <GridColumn className="nwc-grid-col-xs nw-qview-right">
              <ProductRightPanel.component
                className="nw-minipdp-right-panel"
                pdpDetails={this.pdpDetails}
                setMainImage={this.setMainImage}
                addToBag={this.addToBag}
                setStyle={this.setStyle}
                selectSku={this.selectSku}
                selectedSku={selectedSku}
                skuId={skuId}
                ProductLoader={ProductLoader}
                fitGuideModal={this.fitGuideModal}
                selectedQty={selectedQty}
                setSelectedQty={this.setSelectedQty}
                showPriceInfoBlock={
                  process.env.REACT_APP_PRICEINFOBLOCK_ENABLED === "true"
                }
                tenantSizeLabel={this.tenantSizeLabel}
              />
            </GridColumn>
          </GridRow>
        </GridContainer>
        <Modal
          className="nw-pdp-modalsize"
          isOpen={fitGuideModal}
          preventfix={preventfix}
          onClose={this.closeFitGuideModal}
        >
          <PdpFitGuide.component
            brandName={brandName}
            productTitle={name}
            sizeChartId={sizeChartId}
            sizeRecommendation={sizeRecommendation}
          />
        </Modal>
      </div>
    );
  }
}

ProductModal.wrappedComponent.defaultProps = {
  mainImage: Endpoints.noImg,
  isNew: false,
  returnable: undefined,

};

ProductModal.wrappedComponent.propTypes = {
  mainImage: PropTypes.string,
  brandText: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  styleId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  discountRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  mrpRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  sellingPriceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  ProductStore: PropTypes.shape({
    getDetails: PropTypes.func.isRequired,
    productDetails: PropTypes.shape({
      mainImage: PropTypes.string,
    }),
    sizeRecommendation: PropTypes.string,
    returnable: PropTypes.bool.isRequired,
  }).isRequired,
  MybagStore: PropTypes.shape({
    addToBag: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("ProductStore", "MybagStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductModal.wrappedComponent,
  component: ProductModal,
};

export default ProjectComponent;
