/* eslint-disable prettier/prettier */
import { action } from "mobx";
import lodashGet from "lodash.get";
import { Toastr, Utils } from "arv-reactcomponents";
import * as service from "./loginStore.service";
import * as utils from "./loginStore.methods";
import Analytics from "../../analytics";
import { removePromoCode, createCookie, readCookie } from "../../utils";
import Event from "../../analytics/eventFactory";
import loadashGet from "lodash.get";
import {
  STORAGE_KEYS,
  blackboltConfig,
  Checkout,
  Login,
} from "npmlinks-constants";
import GTM from "../../analytics/GTM";

class LoginStoreAction {
  // Login actions goes here
  WishListStore;
  MybagStore;
  constructor(WishListStore, MybagStore) {
    this.WishListStore = WishListStore;
    this.MybagStore = MybagStore;
    this.setStores = this.setStores.bind(this);
    this.logoutAction = this.logoutAction.bind(this);
  }
  setStores(WishListStore, MybagStore) {
    this.WishListStore = WishListStore;
    this.MybagStore = MybagStore;
  }
  @action
  loginUserAction(email, password) {
    return new Promise((resolve, reject) => {
      this.loader = true;
      this.passwordLoginV2Loader = true;
      this.setAuthError();
      const body = process.env.REACT_APP_LOGIN_VERSION === "v2" ? utils.formatLoginQueryV2(email, password) : utils.formatLoginQuery(email, password);
      const type = process.env.REACT_APP_LOGIN_VERSION === "v2" ? "loginV2" : "login";

      return service.login(type, body).then(
        action(res => {
          this.passwordLoginV2Loader = false;
          localStorage.removeItem("failedlogincount");
          this.postLogin(res);
          resolve(res);
        }),
        action(error => {
          const invalidlogin = localStorage.getItem('failedlogincount');
          this.loader = false;
          this.passwordLoginV2Loader = false;
          this.passwordLoginFail = true;
          const defaultErrorMsg = blackboltConfig.ERROR_MSG;
          Analytics.trackEvent({
            action: Event.action.LOGIN_ERROR,
            category: Event.category.LOGIN,
          });
          GTM.pushEvent(Event.GTMEvent.LOGIN_FAILED);
          const err = error && error.errors;
          if (
            err &&
            err[0] &&
            blackboltConfig.ERROR_CODE.includes(err[0].status)
          ) {
            if (!invalidlogin) {
              localStorage.setItem('failedlogincount', "1");
            }
            else {
              localStorage.setItem('failedlogincount', (parseInt(invalidlogin, 10) + 1).toString());
              if (process.env.REACT_APP_LOGIN_CAPTCHA_ENABLED === "true" &&
                parseInt(invalidlogin, 10) >= Login.LOGIN_MESSAGES.MAX_INVALID_LOGIN_ATTEMPT) {
                this.captcha = true;
              }
            }
            const { detail = defaultErrorMsg } = err[0];
            this.setAuthError(true, detail);
            this.passwordAuthErrorMsg = detail;
          } else {
            this.setAuthError(true, defaultErrorMsg);
            this.passwordAuthErrorMsg = defaultErrorMsg;
          }
          reject(error);
        }),
      );
    })
  }


  @action
  triggerGTMforLogin(accountInfoData, eventName) {
    const userDetails = {
      customer_id: accountInfoData.userId,
      email: this.email,
      name: accountInfoData.userInfo.firstName,
      phone_number: this.mobileNumber,
    };
    GTM.pushWithEvent({ userDetails }, eventName);
  }

  @action
  postLogin(response) {
    this.currentView = "login";
    const res = response.data.tokenDetails || response.data;
    if (res.access_token && res.refresh_token) {
      const decodedAccessToken = utils.decodeToken(res.access_token);
      const decodedRefreshToken = utils.decodeToken(res.refresh_token);
      const { user_id } = decodedAccessToken;

      Analytics.trackEvent({
        action: Event.action.LOGIN_SUCCESS,
        category: Event.category.LOGIN,
        label: user_id,
      });

      utils.setTokens(res);
      this.setloginModal(false)
        .setLoggedInData()
        .getAccountInfo();
      // MyBagStore.getMyBagDetails();

      // TODO move this once new token data
      this.accessTokenData = {
        ...this.accessTokenData,
        ...decodedAccessToken,
      };
      this.refreshTokenData = {
        ...this.refreshTokenData,
        ...decodedRefreshToken,
      };
      this.loader = false;
      GTM.pushEvent(Event.GTMEvent.LOGIN_SUCCESSFUL);
    } else {
      this.setAuthError(true, Login.LOGIN_MESSAGES.INVALID_DATA);
    }
  }

  updateAccessTokenData() {
    const decodedAccessToken = utils.decodeToken(res.access_token);
  }

  @action
  phoneNoVerification(mobileNumber) {
    this.changeView("loginOtp");
    this.goBackToLoginSub = true;
    this.setMobileNumberV2(mobileNumber);
    this.setShowHeader(true);
    this.setShowOtp(true);
  }

  @action
  loginUserSocially(socialToken, type) {
    this.socialLoader = true;
    const serviceType = process.env.REACT_APP_LOGIN_VERSION === "v2" ? "loginV2" : "socialLogin";
    const req = JSON.stringify({
      social_token: socialToken,
      grant_type: blackboltConfig.socialGrantType,
      social_identity_provider: type,
    });
    service.socialLogin(serviceType, req).then(
      action(res => {
        this.socialLoader = false;
        if (res && res.data && res.data.access_token) {
          Analytics.trackEvent({
            action: Event.action.LOGIN_SUCCESS,
            category: Event.category.LOGIN,
          });
          this.postLogin(res);
        }
      }),
      action(err => {
        const { data } = err;
        if (data) {
          const { emailId, missingFields } = data;
          Analytics.trackEvent({
            action: Event.action.MISSED_FIELDS,
            category: Event.category.LOGIN,
          });
          this.missingFieldsLogin = {
            ...this.missingFieldsLogin,
            emailId,
            missingFields,
            socialToken,
            source: type,
          };
          this.changeView("missed");
        }
        else {
          const errCode = lodashGet(err, "errors[0].code", null);
          const mobileNoMissField = lodashGet(err, "errors[0].data.mobileNumber");
          this.socialLoader = false;
          if (errCode === "605") {
            this.phoneNoVerification(mobileNoMissField);
            Analytics.trackEvent({
              action: Event.action.PHONE_VERIFY,
              category: Event.category.LOGIN,
            });
          } else if (type === blackboltConfig.facebook) {
            this.changeView("socialLoginError");
            this.setAuthError(true, Login.LOGIN_MESSAGES.FB_CREDENTIAL_ERROR);
          } else {
            this.changeView("socialLoginError");
            this.setAuthError(
              true,
              Login.LOGIN_MESSAGES.FAILED_TO_LOGIN_WITH_GMAIL,
            );
          }
        }
      }),
    );
  }

  @action
  statusCheck() {
    return new Promise((resolve, reject) => {
      if (!window.navigator.userAgent.match(/wv/i)) {
        resolve(false);
      }
      const { FB } = window;
      FB.getLoginStatus(response => {   // See the onlogin handler
        resolve(response);
      });
    });
  }

  @action
  fbCallBack(response) {
    switch (response.status) {
      case "connected": {
        this.socialLoader = false;
        const { accessToken } = response.authResponse;
        this.loginUserSocially(accessToken, blackboltConfig.facebook);
        break;
      }
      case "unknown":
      case "not_authorized":
      default: {
        this.socialLoader = false;
        this.changeView("login");
        break;
      }
    }
  }

  @action
  setShowOtp(value) {
    this.showOtp = value;
  }

  @action
  fbAction() {
    this.statusCheck()
      .then(response => {
        if (response && response.status && response.status === "connected") {
          this.fbCallBack(response);
        } else {
          this.socialLoader = true;
          const { FB } = window;
          if (!FB) {
            this.socialLoader = false;
            Toastr.showToastr({
              className: "nwc-toastr-list-notification nw-toastr",
              message: Login.LOGIN_MESSAGES.UNABLE_TO_CONNECT_TO_FB,
              timeout: 3000,
            });
            return null;
          }

          FB.login(
            response => {
              // TODO clean this up
              this.fbCallBack(response);
            },
            {
              scope: "email", // publish_actions, removed for now
            },
          );
        }
      })
      .catch(err => {
        this.fbAction();
      });
    return null;
  }

  @action
  forgotPasswordActionV2(id, type) {
    const { headers, body } = utils.formatResetPasswordQuery(id, type);
    this.loader = true;
    service.forgotPassword(headers, body).then(
      action(res => {
        this.loader = false;
        Analytics.trackEvent({
          action: Event.action.FORGOT_PASSWORD_SUCCESS,
          category: Event.category.LOGIN,
        });
        this.changeView("forgotPasswordV2");
      }),
      action(error => {
        this.loader = false;
        const err = error && error.errors;
        if (
          err &&
          err[0] &&
          blackboltConfig.ERROR_CODE.includes(err[0].status)
        ) {
          const { detail = defaultErrorMsg } = err[0];
          this.setAuthError(true, detail);
        } else {
          this.setAuthError(true, blackboltConfig.ERROR_MSG);
        }
      }),
    );
  }

  @action
  getAccountInfo() {
    return service.getAccount().then(res => {
      const { firstName: userName } = res.userInfo;
      const [email] = res.emails;
      window.localStorage.setItem("user_email", email.emailID);
      const [{ phoneNumber, verified } = {}] = res.mobileNumbers.filter(
        number => number.type === "Primary",
      );
      utils.createCookies(
        { userName: userName || "" },
        { cust_email: email.emailID },
        { mobileNumber: phoneNumber },
        { verified: verified }
      );
      this.firstName = userName;
      this.userName = userName;
      this.mobileNumber = phoneNumber;
      this.email = email.emailID;
      this.account = {
        ...this.account,
        ...res,
      };
      if (this.isNewUser) {
        this.triggerGTMforLogin(res, Event.GTMEvent.REGISTER);
        this.isNewUser = false;
      }
      this.triggerGTMforLogin(res, Event.GTMEvent.LOGIN);
      this.postLoginExternalActions();
    });
  }
  @action
  postLoginExternalActions() {
    if (window.goToRouteOnLogin) {
      window.location.href = `
      ${window.location.origin}/${window.goToRouteOnLogin}`;
    } else if (
      !Checkout.CHECKOUT_PATHNAMES.includes(window.location.pathname)
    ) {
      this.MybagStore.getMyBagDetails();
    }
    this.WishListStore.wishList();
    this.WishListStore.brandWishList();
  }

  @action
  logoutAction(interaction = false) {
    Analytics.trackEvent({
      action: Event.action.LOGOUT,
      category: Event.category.LOGIN,
      interaction,
    });
    GTM.pushEvent(Event.GTMEvent.LOGOUT);
    this.account = {};
    this.firstName = "";
    this.isUserLoggedIn = false;
    this.isNewUser = false;
    removePromoCode();
    // TODO move cookie list to constants
    utils.deleteCookies(
      "userName",
      "accessToken",
      "refreshToken",
      "cust_email",
      "mobileNumber",
      "lastName",
      "verified"
    );

    window.localStorage.removeItem("user_email");
    Utils.localStorage.removeItem(STORAGE_KEYS.RECENTLY_VIEWED);
  }

  @action
  checkUserRoles() {
    return utils.checkUserRoles();
  }

  @action
  setAuthError(
    isError = false,
    msg = Login.LOGIN_MESSAGES.DEFAULT_VALID_DATA_MESSAGE,
  ) {
    this.authError = isError;
    this.authErrorMsg = msg;
  }

  @action
  setAuthErrorForOTPLogin(
    isError = false,
    msg = Login.LOGIN_MESSAGES.DEFAULT_VALID_DATA_MESSAGE, ) {
    this.authError = isError;
    this.otpAuthErrorMsg = msg;
  }

  @action
  setLoggedInData() {
    // TODO : set roles and other token extracted data

    this.isUserLoggedIn = true;
    return this;
  }

  @action
  setloginModal(value, preventClose = false) {
    const invalidlogin = localStorage.getItem('failedlogincount');
    if (process.env.REACT_APP_LOGIN_CAPTCHA_ENABLED === "true" &&
      invalidlogin !== null &&
      parseInt(invalidlogin, 10) >= Login.LOGIN_MESSAGES.MAX_INVALID_LOGIN_ATTEMPT) {
      this.captcha = true;
      this.disabledbutton = true;
    }
    this.preventClose = preventClose;
    this.isModalOpen = value;
    if(value){
      Analytics.trackEvent({
        action: Event.action.LOGIN_STARTED,
        category: Event.category.LOGIN,
      });
    }
    this.setAuthError();
    return this;
  }

  @action
  changeDisabledButton(value) {
    this.disabledbutton = value;
    this.captcha = false;
    return this;
  }

  @action
  forgotPasswordAction(id, type) {
    this.loader = true;
    const { headers, body } = utils.formatResetPasswordQuery(id, type);
    service.forgotPassword(headers, body).then(
      action(res => {
        Analytics.trackEvent({
          action: Event.action.FORGOT_PASSWORD_SUCCESS,
          category: Event.category.LOGIN,
        });
        this.setloginModal(false);
        this.changeView("login");
        Toastr.showToastr({
          className: "nwc-toastr-list-notification nw-toastr",
          message: `${type} ${Login.LOGIN_MESSAGES.MESSAGE_SENT}`,
          timeout: 3000,
        });
      }),
      action(error => {
        this.loader = false;
        const err = error && error.errors;
        if (
          err &&
          err[0] &&
          blackboltConfig.ERROR_CODE.includes(err[0].status)
        ) {
          const { detail = defaultErrorMsg } = err[0];
          this.setAuthError(true, detail);
        } else {
          this.setAuthError(true, blackboltConfig.ERROR_MSG);
        }
      }),
    );
  }

  @action
  resetPasswordAction(sessionId, newPassword, type) {
    return new Promise((resolve, reject) => {
      this.loader = true;
      const body = JSON.stringify({
        newPassword,
      });
      const headers = {
        "X-Domain": process.env.REACT_APP_DOMAIN_NAME,
        "X-Channel": blackboltConfig.channel,  
        "X-Communication-Type": type,
        "Content-Type": "application/json",
      };
      service.resetPassword(sessionId, body, headers).then(
        action(() => {
          this.loader = false;
          resolve();
        }),
        action(error => {
          this.loader = false;
          reject(error);
        }),
      );
    });
  }

  // Register actions goes here
  @action
  userOnboard(userDetails, missingForm) {
    return new Promise((resolve, reject) => {
      this.loader = true;
      let body = {};
      if (missingForm) {
        const partialCache = this.missingFieldsLogin;
        body = utils.formatSocialQuery({ ...partialCache, ...userDetails });
      } else {
        body = utils.formatRegisterQuery(userDetails);
      }
      return service.register("registerUser", body).then(
        action(res => {
          this.isNewUser = true;
          this.postLogin(res);
          Analytics.trackEvent({
            action: Event.action.REGISTER_SUCCESS,
            category: Event.category.LOGIN,
          });

          GTM.pushEvent(Event.GTMEvent.REGISTER_SUCCESSFUL);

          Toastr.showToastr({
            className: "nwc-toastr-list-notification nw-toastr",
            message: Login.LOGIN_MESSAGES.SUCCESSFULL_SIGNUP_MESSAGE,
            timeout: 3000,
          });
          resolve(res);
        }),
        action(err => {
          this.loader = false;
          reject(err);
        }),
      );
    });
  }

  @action
  registerWithOtp(userDetails, otpDetails={},missingForm=false) {
    return new Promise((resolve, reject) => {
      this.loader = true;
      let body = {};
        body = utils.formatRegisterWithOtpQuery({...userDetails,...otpDetails});
      if (missingForm) {
        const partialCache = this.missingFieldsLogin;
        body = utils.formatSocialQueryWithOtp({ ...partialCache, ...userDetails, ...otpDetails });
      } else {
        body = utils.formatRegisterWithOtpQuery({ ...userDetails, ...otpDetails });
      }
      return service.registerWithOtp("registerWithOtp", body).then(
        action(res => {
          this.isNewUser = true;
          this.postLogin(res);
          Analytics.trackEvent({
            action: Event.action.REGISTER_SUCCESS,
            category: Event.category.LOGIN,
          });

          GTM.pushEvent(Event.GTMEvent.REGISTER_SUCCESSFUL);

          Toastr.showToastr({
            className: "nwc-toastr-list-notification nw-toastr",
            message: Login.LOGIN_MESSAGES.SUCCESSFULL_SIGNUP_MESSAGE,
            timeout: 3000,
          });
          resolve(res);
        }),
        action(error => {
          this.loader = false;
          const detail = lodashGet(error, "errors[0].detail", blackboltConfig.ERROR_MSG);
          this.setAuthErrorForOTPLogin(true, detail);
          reject(error);
        }),
      );
    });
  }

  @action
  changeView(view, additionalData = {}) {
    this.setAuthError();
    this.currentView = view;
    this.userId = additionalData.userId ? additionalData.userId : "";
    this.showOtp = additionalData.showOtp;
    this.loader = false;
    this.socialLoader = false;
    this.isModalOpen = true;
  }

  @action
  setMobileNoEntered(value) {
    this.mobileNoEntered = value;
  }

  @action
  setHideCloseBtn(value) {
    this.hideCloseBtn = value;
  }


  @action
  updateUserInfoAction(req) {
    return new Promise((resolve, reject) => {
      service
        .updateUserInfo(req)
        .then(res => {
          createCookie("userName", res.firstName);
          this.firstName = res.firstName;
          this.userName = res.firstName;
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  setLoader(bool) {
    this.loader = bool;
  }

  @action
  otpUserRegistration(res) {
    this.isNewUser = true;
    this.postLogin(res);
    Analytics.trackEvent({
      action: Event.action.REGISTER_SUCCESS,
      category: Event.category.LOGIN,
    });

    GTM.pushEvent(Event.GTMEvent.REGISTER_SUCCESSFUL);

    Toastr.showToastr({
      className: "nwc-toastr-list-notification nw-toastr",
      message: Login.LOGIN_MESSAGES.SUCCESSFULL_SIGNUP_MESSAGE,
      timeout: 3000,
    });
  }

  formatSocialNormalRegisterQuery(userDetails, missingForm) {
    let body;
    if (missingForm) {
      const partialCache = this.missingFieldsLogin;
      body = utils.formatSocialQuery({ ...partialCache, ...userDetails });
    } else {
      body = utils.formatRegisterQuery(userDetails);
    }
    return body;
  }

  @action
  checkUserExist(mobileNo, email) {
    const body = {
      "mobileNumber": {
        "mobileNumber": mobileNo,
        "countryCallingCode": "+91"
      },
      "emailId": email
    }
    this.userMobileNumberV2 = mobileNo;
    this.loader = true;
    return new Promise((resolve, reject) => service.checkUser({}, body).then(
      action(res => {
        this.loader = false;
        const { message = defaultErrorMsg, mobileNumberPresent } = res
        this.userExistErrorMsg = mobileNumberPresent && message;
        resolve(res);
      }),
      action(err => {
        this.loader = false;
        reject(err);
      }),
    ));
  }

  @action
  loginWithOtp(mobileNo, otpDetails) {
    const body = utils.formatOtpLoginQuery({ mobileNo, ...otpDetails });
    this.loader=true;
    return service.loginV2(body).then(
      action(res => {
        localStorage.removeItem("failedlogincount");
        this.postLogin(res);
        return res;
      }),
      action(error => {
        const invalidlogin = localStorage.getItem('failedlogincount');
        this.loader = false;
        // this.otpLoginFail = true;
        const defaultErrorMsg = blackboltConfig.ERROR_MSG;
        Analytics.trackEvent({
          action: Event.action.LOGIN_ERROR,
          category: Event.category.LOGIN,
        });
        GTM.pushEvent(Event.GTMEvent.LOGIN_FAILED);
        const err = error && error.errors;
        if (
          err &&
          err[0] &&
          blackboltConfig.ERROR_CODE.includes(err[0].status)
        ) {
          if (!invalidlogin) {
            localStorage.setItem('failedlogincount', "1");
          }
          else {
            localStorage.setItem('failedlogincount', (parseInt(invalidlogin, 10) + 1).toString());
            if (process.env.REACT_APP_LOGIN_CAPTCHA_ENABLED === "true" &&
              parseInt(invalidlogin, 10) >= Login.LOGIN_MESSAGES.MAX_INVALID_LOGIN_ATTEMPT) {
              this.captcha = true;
              this.disabledbutton=true;
            }
          }
          this.disableSigninButton = err[0].code === blackboltConfig.ACCOUNT_LOCKOUT_CODE;
          const { detail = defaultErrorMsg } = err[0];
          this.setAuthErrorForOTPLogin(true, detail);
        } else {
          this.setAuthErrorForOTPLogin(true, defaultErrorMsg);
        }
        return error;
      }),
    );
  }

  @action
  clearUserId() {
    this.userMobileNumberV2 = "";
    this.userEmailV2 = "";
  }

  @action
  getPasswordValidationRegex() {
    return new Promise((resolve, reject) => {
      service.passwordValidationRegex()
        .then(response => {
          this.passwordValidationRegex = lodashGet(response, "data", this.passwordValidationRegex);
          resolve(response);
          this.passwordRegexLoader = false;
        })
        .catch(error => {
          this.passwordRegexLoader = false;
          reject(error);
        });
    });
  }

  @action
  setMobileNumberV2(number) {
    this.userMobileNumberV2 = number;
  }

  @action
  setShowHeader(value) {
    this.showHeader = value;
  }

  @action
  setEmailV2(email) {
    this.userEmailV2 = email;
  }

  @action
  resetErrorMsg(value) {
    this.userExistErrorMsg = value;
    this.registerWithOTPErrorMsg = value;
    this.otpAuthErrorMsg = value;
    this.passwordAuthErrorMsg = value;
  }
}

export default LoginStoreAction;