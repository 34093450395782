import { action } from "mobx";
import { onPaymentValidate } from "./service";
import {
  convertToPaise,
  redirect,
  razorpayConstruncts,
} from "../../utils";
import { PAYMENTPAGE } from "npmlinks-constants";
const { COMPONENTWALLET_MAPPING, RAZORPAY_CURRENCY_INR, PAYMENT_GATEWAYS } = PAYMENTPAGE;
const{ 
  createRPNetbankingRequest,
  createRPCardRequest,
  createRPUpiRequest,
  createRPWalletRequest,
  createRPSavedCardRequest,
  createRPCardlessEmiRequest,
  createRPGpayRequest
} = razorpayConstruncts;

class RazorpayStoreActions {

  @action
  initRazorpay(amount, paymentRequest) {
    try {
      const { razorpayKey: key, requestedPG } = paymentRequest && paymentRequest.paymentDetails;
      let { transactionId, orderId } = paymentRequest;
      let callbackURL = window.location.origin + "/api/payment/confirmationRP/" + transactionId + "/callback/" + orderId + "/" + process.env.REACT_APP_TENANT_ID;
      if (key && amount && requestedPG === PAYMENT_GATEWAYS.RAZORPAY && process.env.REACT_APP_RAZORPAY) {
        const amountInPaise = convertToPaise(amount);
        this.razorpayObject = new Razorpay({
          key,
          amount: amountInPaise,
          currency: RAZORPAY_CURRENCY_INR,
          redirect: true,
          callback_url: callbackURL,
        });
        if (this.upiMethod == "gpay") {
          this.razorpayObject.on("ready", res => {
            console.log(res);
          });
        } else {
          this.razorpayObject.on("ready", res => {
            console.log(res);
          });
          this.razorpayRequest = {
            amount: amountInPaise
          };
          this.razorpayObject.createPayment(this.razorpayRequest, {
            paused: true
          });
        }
        this.razorpayObject.on('payment.error', function (resp) {
         if(!stayOnpaymentPage) {
          redirect(`checkout/onpaymentfailure`);
         }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  @action
  loadRazorpay(componentName, razorpayArgs, formData) {
    return new Promise((resolve, reject) => {
      let razorpayRequest = {};
      switch (componentName) {
        case COMPONENTWALLET_MAPPING.CREDIT_CARD: razorpayRequest = createRPCardRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName]);
          break;
        case COMPONENTWALLET_MAPPING.NET_BANKING: razorpayRequest = createRPNetbankingRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName]);
          break;
        case COMPONENTWALLET_MAPPING.UPI: razorpayRequest = createRPUpiRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName], formData.upiId);
          break;
        case COMPONENTWALLET_MAPPING.E_WALLET: razorpayRequest = createRPWalletRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName]);
          break;
        case COMPONENTWALLET_MAPPING.CARDLESS_EMI: razorpayRequest = createRPCardlessEmiRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName]);
          break;
        case COMPONENTWALLET_MAPPING.SAVEDCARD: razorpayRequest = createRPSavedCardRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName]);
          break;
        default: break;
      }

      if (razorpayRequest) {
        Object.keys(razorpayRequest).forEach(key => {
          if (!razorpayRequest[key]) {
            delete razorpayRequest[key];
          }
        });
      }

      if (!this.razorpayObject) {
        this.razorpayObject = new Razorpay({
          key: razorpayArgs.RAZORPAY_KEY,
        });
        this.razorpayObject.createPayment(razorpayRequest);
      } else {
        this.razorpayObject.focus();
        Object.entries(razorpayRequest).forEach(([key, value]) => {
          this.razorpayRequest[key] = value;
        });
        this.razorpayObject.emit('payment.resume');
      }
      this.razorpayObject.on('payment.success', function (resp) {
        resolve(resp);
      });
      this.razorpayObject.on('payment.error', function (resp) {
        reject(resp);
      });
    });
  }

  @action
  loadRazorpayGpay(componentName, razorpayArgs){
    return new Promise((resolve,reject)=>{
      let razorpayRequest = {};
      razorpayRequest = createRPGpayRequest(razorpayArgs, PAYMENTPAGE.RAZORPAY_PAYMENT_MODE[componentName]);
      this.razorpayObject = new Razorpay({
        key: razorpayArgs.RAZORPAY_KEY,
      });

      this.razorpayObject.createPayment(razorpayRequest, { gpay: true })
      .on('payment.success', function(response) {
        resolve(response);
      
      })
      .on('payment.error', function(error) {
        reject(error);
        
      })
    })
  }

  @action
  cancelRazorpay() {
    if (this.razorpayObject) {
      this.razorpayObject.emit('payment.cancel');
    }
  }

  @action
  cancelRazorpay2() {
    if (this.razorpayObject) {
      this.razorpayObject.emit('payment.cancel');
      this.stayOnpaymentPage = true;
    }
  }

  @action
  setStayOnpaymentPage() {
    this.stayOnpaymentPage = false;
  }


  @action
  onPaymentValidate(body) {
    return new Promise((resolve, reject) => {
      onPaymentValidate(JSON.stringify(body))
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  setUpi(upiId) {
    this.upiId = upiId;
  }

  @action
  setUpiMethod(method) {
    this.upiMethod = method;
  }

  @action
  setSuccessFlow(paymentData) {
    this.paymentData = Object.assign({}, paymentData);
  }

  @action
  setUPIData(upiData) {
    this.upiData = upiData;
  }
}

export default RazorpayStoreActions;